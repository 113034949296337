import "./assets/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App2";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/";
import * as serviceWorker from "./serviceWorker";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_ID,
};

TagManager.initialize(tagManagerArgs);

const palette = {
  primary: {
    dark: "#4096D2",
    main: "#D5EFFC",
    light: "#EEF9FE",
  },
  secondary: {
    main: "#009444",
  },
  text: {
    primary: "#282560",
  },
};

const theme = createMuiTheme({
  typography: {
    h1: {
      color: palette.text.primary,
      fontFamily: "Futura-Medium",
      fontWeight: 800,
      fontSize: "24px",
      letterSpacing: "0px",
      lineHeight: "32px",
    },
    h2: {
      color: palette.text.primary,
      fontFamily: "Open Sans",
      fontWeight: 400,
      fontSize: "24px",
      letterSpacing: "0px",
      lineHeight: "33px",
    },
    h3: {
      color: palette.text.primary,
      fontFamily: "Futura-Medium",
      fontWeight: 700,
      fontSize: "20px",
      letterSpacing: "0px",
      lineHeight: "27px",
    },
    h4: {
      color: palette.text.primary,
      fontFamily: "Futura-Medium",
      fontSize: 18,
      fontWeight: 700,
      lineHeight: "25px",
      letterSpacing: "0px",
    },
    body1: {
      color: palette.text.primary,
      fontFamily: "Open Sans",
      fontWeight: 400,
      fontSize: "14px",
      letterSpacing: "0px",
      lineHeight: "19px",
    },
    body2: {
      color: palette.text.primary,
      fontFamily: "Open Sans",
      fontWeight: 400,
      fontSize: "12px",
      letterSpacing: "0px",
      lineHeight: "17px",
    },
    subtitle2: {
      color: palette.text.secondary,
      fontWeight: 400,
      fontSize: "14px",
      letterSpacing: "-0.05px",
      lineHeight: "21px",
    },
    button: {
      color: palette.text.primary,
      fontFamily: "Futura",
      fontWeight: 700,
      fontSize: "16px",
      letterSpacing: "0px",
      lineHeight: "21px",
      textTransform: "none",
    },
    numbers: {
      color: palette.text.primary,
      fontFamily: "PT Sans",
      fontWeight: 700,
      fontSize: "16px",
      letterSpacing: "0px",
      lineHeight: "23px",
    },
    caption: {
      color: palette.text.secondary,
      fontSize: "11px",
      letterSpacing: "0.33px",
      lineHeight: "13px",
    },
    overline: {
      color: palette.text.secondary,
      fontSize: "11px",
      fontWeight: 500,
      letterSpacing: "0.33px",
      lineHeight: "13px",
      textTransform: "uppercase",
    },
  },
  palette,
});

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <App />
  </MuiThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

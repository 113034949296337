import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

import coinsImg from '../assets/img/dashboard/coins.png'
import menuImage from '../assets/img/dashboard/menu.png'


import Font from '../constants/FontSize'

export default class MobileTopNav extends Component {
    render() {
        const { toggleNav, profilePic, balance, innerWidth, innerHeight, vw } = this.props
        const lineHeight = (size) => String(1.3 * size) + 'px'
        let fs = Font.big
        fs = innerWidth < 768 ? Font.med : fs
        fs = innerWidth < 350 ? Font.small : fs
        return (
            <div style={{
                display: 'flex', marginTop: vw(5), marginBottom: vw(5), paddingLeft: 50, paddingRight: 15,
                flexDirection: 'row', justifyContent: 'space-between'
            }}>
                <div onClick={toggleNav}
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img style={{ width: vw(9), height: vw(9) }} src={menuImage} />
                </div>
                <NavLink to='/account' style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'flex-start' }}>
                    <div style={{ height: vw(15), width: vw(15), borderRadius: vw(7.5), overflow: 'hidden' }}>
                        <img style={{ width: '100%', height: '100%' }} src={profilePic} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginRight: vw(4) }}>
                        <img style={{ width: vw(9), height: vw(9) }} src={coinsImg} />
                        <p style={{ fontFamily: 'Open Sans', fontSize: fs.p1, lineHeight: lineHeight(fs.p1), letterSpacing: 0, color: '#EF4137', opacity: 1 }}>{balance} CA$H</p>
                    </div>
                </NavLink>
            </div>
        )
    }
}

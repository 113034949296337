import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { fade } from "@material-ui/core/styles/colorManipulator";

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 20
  },
  content: {},
  inner: {
    width: "100%",
    textAlign: "center"
  },
  title: {
    textAlign: "center",
    color: theme.palette.text.primary,
    marginTop: 20
  },
  level: {
    textAlign: "center",
    color: theme.palette.primary.dark,
    marginTop: 20
  },
  button: {
    textTransform: "none",
    color: "#FFFFFF"
  },
  cashout: {
    borderRadius: 20,
    width: 200,
    marginTop: 50,
    background: theme.palette.primary.dark,
    "&:hover": {
      backgroundColor: fade(theme.palette.primary.dark, 0.2)
    }
  }
}));

function LevelUp() {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <div className={classes.inner}>
          <Typography variant="h1" className={classes.title}>
            Your Smart Profile is
          </Typography>
          <Typography variant="h2" className={classes.level}>
            Level 3
          </Typography>
          <Typography variant="body1" className={classes.title}>
            You can increase your level by participating in Fabric’s data-driven
            ad campaigns and by providing information to Fabric’s consumer
            questions.
          </Typography>
          <Button
            component={Link}
            className={classes.cashout}
            classes={{ label: classes.button }}
            size="large"
            to="/ad/requests"
          >
            View Available Ads
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}

export default LevelUp;

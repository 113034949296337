export default [[false, false, 123, {
    "_id": "ad$coca",
        "id": 6,
            "ad/finished": true,
                "ad/name": "Coca Cola Classic Ad",
                    "ad/video": "initialData/ads/a_coke_is_a_coke.mp4",
                        "ad/coverImage": "initialData/ads/cocaColaAdCoverImage.png",
                            "ad/thumbnail": "initialData/ads/cocaColaAdThumbnail.png",
                                "ad/question": "What key word describes the overall message in the Coca-Cola video?",
                                    "ad/answerChoices": ["Bears", "Unity", "Together"],
                                        "ad/correctAnswer": "Together",
                                            "ad/videoPoints": 10,
                                                "ad/questionPoints": 30,
                                                    "ad/postPoints": 30,
                                                        "company": {
        "_id": "company$coke",
            "company/name": "Coca Cola",
                "phoneNumber": "5239027890",
                    "address": "1 Coca Cola Lane, Soda City, NY",
                        "tags": ["beverage", "food"],
                            "company/logo": "initialData/companies/cocaColaLogo.png"
    },
    "ad/tagline": "Together, we can make a world of difference.",
        "ad/cashtag": "$TogetherIsBeautiful",
            "ad/categories": [["category/descriptiveName", "groceries.pantry"]],
                "ad/budget": 1000,
                    "ad/budgetRemaining": 1000
}],
    [false, false, 456, {
    "_id": "ad$tide",
        "ad/id": 5,
            "ad/finished": true,
                "ad/name": "Tide Alien Ad",
                    "ad/video": "initialData/ads/a_coke_is_a_coke.mp4",
                        "ad/coverImage": "initialData/ads/tideCoverImage.png",
                            "ad/thumbnail": "initialData/ads/tideAdThumbnail.png",
                                "ad/question": "What key word describes the overall message in the Tide video?",
                                    "ad/answerChoices": ["Bears", "Unity", "Together"],
                                        "ad/correctAnswer": "Together",
                                            "ad/videoPoints": 10,
                                                "ad/questionPoints": 30,
                                                    "ad/postPoints": 30,
                                                        "company": {
        "_id": "company",
            "company/name": "Tide",
                "phoneNumber": "7628950980",
                    "address": "1 Tide Drive, Laundry City, AK",
                        "tags": ["home", "laundry"],
                            "company/logo": "initialData/companies/tideLogo.png"
    },
    "ad/tagline": "Tide!",
        "ad/cashtag": "$WashYerClothes",
            "ad/categories": [["category/descriptiveName", "household"]],
                "ad/budget": 1000,
                    "ad/budgetRemaining": 1000
}]]
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import lockImg from "../assets/img/navSec/lock.png";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: 60,
    width: 225,
    marginBottom: 20,
    marginRight: 40
  },
  linkText: {
    paddingTop: 15,
    paddingLeft: 20
  },
  text: {
    height: 56,
    width: 189,
    textAlign: "center",
    color: "white",
    opacity: 1
  }
}));

function RedeemBalanceButton() {
  const classes = useStyles();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 50
      }}
    >
      <div
        style={{
          position: "relative",
          height: 79,
          width: 211,
          background: "rgb(215, 161, 162) 0% 0% no-repeat padding-box",
          boxShadow: "0px 3px 6px #00000029",
          opacity: 1
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Typography variant="h6" className={classes.text}>
            Redeem Balance (300 SCA$H)
          </Typography>
        </div>
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <div
            style={{
              zIndex: 10,
              height: 61,
              width: 61,
              opacity: 1,
              background: `transparent url(${lockImg}) 0% 0% no-repeat padding-box`
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}

RedeemBalanceButton.propTypes = {};

export default RedeemBalanceButton;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { usePromiseTracker } from "react-promise-tracker";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden"
  },
  label: {
    marginTop: 10,
    color: theme.palette.primary.main
  }
}));

function LoadingSpinner(props) {
  const { promiseInProgress } = usePromiseTracker();
  const classes = useStyles();
  if (promiseInProgress) {
    // debugger
  }
  return (
    <>
      <Dialog
        open={promiseInProgress}
        PaperProps={{
          classes: {
            root: classes.paper
          }
        }}
      >
        <CircularProgress></CircularProgress>
        <Typography variant="subtitle2" className={classes.label}>
          Loading...
        </Typography>
      </Dialog>
    </>
  );
}

export default LoadingSpinner;

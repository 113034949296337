import React, { useContext } from 'react';
import fabricLogo from '../assets/img/navSec/FabricLogo.png';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Hidden, Button } from '@material-ui/core';
import NavBarItem from './NavBarItem';
import RedeemBalanceButton from './RedeemBalanceButton';
import UserContext from '../contexts/UserContext';
import { requestCard } from '../utils/functions';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 354,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    // padding: 20,
    background: theme.palette.primary.main,
    height: '100%',
    flex: 1
  },
  link: {
    display: 'flex',
    // flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-start'
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    [theme.breakpoints.up('md')]: {
      marginBottom: 50
    }
  },
  logoImage: {
    margin: 20,
    height: 86,
    width: 133,
    opacity: 1
  },
  logoutContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 29
  },
  logout: {
    height: 63,
    width: 210,
    borderRadius: 30,
    background: theme.palette.text.primary,
    color: '#FFFFFF',
    font: 'normal normal normal 20px/27px Open Sans',
    position: 'absolute',
    bottom: 30,
    border: `3px solid ${theme.palette.text.primary}`,
    '&:hover': {
      // backgroundColor: '#FFFFFF',
      backgroundColor: 'transparent',
      color: theme.palette.text.primary
    }
  }
}));

function NavBar() {
  const classes = useStyles();
  const { refreshUser, user } = useContext(UserContext);

  return (
    <Paper className={classes.root}>
      <div className={classes.logoContainer}>
        <Hidden mdUp>
          <a href="/">
            <div
              className={classes.logoImage}
              style={{
                background: `transparent url(${fabricLogo}) 0% 0% no-repeat padding-box`
              }}
            ></div>
          </a>
        </Hidden>
      </div>
      <div className={classes.link}>
        <NavBarItem text="Dashboard" linkTo="/" />
        {/* <NavBarItem text="Create Post" linkTo="/user/posts" /> */}
        <NavBarItem text="CA$HFEED" linkTo="/ad/requests" />
        {/* <NavBarItem text="Smart Profile" linkTo="/smart-profile" /> */}
        {/* <NavBarItem text="Transactions" linkTo="/transactions" />
        <NavBarItem text="Redeem CA$H" linkTo="/redeem" /> */}
        {/* <button
          onClick={() =>
            requestCard({
              email: user['user/email'],
              name: `${user['user/firstName']} ${user['user/lastName']}`,
              earnings: user['user/wallet']['wallet/earnings']
            })
          }
        >
          Get Card
        </button> */}
      </div>

      {/* <RedeemBalanceButton></RedeemBalanceButton> */}

      <div className={classes.logoutContainer}>
        <button
          onClick={() => {
            localStorage.removeItem('stringBean');
            refreshUser();
          }}
          variant="contained"
          className={classes.logout}
        >
          Log Out
        </button>
      </div>
    </Paper>
  );
}

export default NavBar;

import React, { Component, useState } from "react";
import get from "lodash.get";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button as MuiButton,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";

import Register from "../components/Register";
import AddCompany from "../components/AddCompany";
import AddAd from "../components/AddAd";
import LoadingSpinner from "../components/LoadingSpinner";

import generateUserTx from "../bootstrap/users";
import { tx1, tx2, tx3, tx4, tx5, tx6, tx7 } from "../bootstrap/vanessaBalanceChanges";
import adTx from "../bootstrap/ads";

import { flureeFetch, getToken } from "../flureeFetch";
import { Button, Table, Form, Modal } from "react-bootstrap";

import { CSVLink } from "react-csv";
import Navigation from "../components/Navigation";

import { generateKeyPair, getSinFromPublicKey } from "fluree-cryptography";
import { trackPromise } from "react-promise-tracker";

const Users = (props) => {
  return (
    <div style={{ padding: "10px 50px" }}>
      <Table striped bordered hover style={{ tableLayout: "fixed", wordBreak: "break-word" }}>
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Handle</th>
            <th>Shopping Interest</th>
            <th>Wallet</th>
            <th>Applied</th>
            <th>Date Applied</th>
            <th>Cell Phone</th>
            <th>Birthday</th>
            <th>Ads</th>
          </tr>
        </thead>
        <tbody>
          {props.users.map((user) => {
            const categories = [];
            const shoppingInterest = get(user, "user/shoppingInterests");
            let cat;
            if (shoppingInterest) {
              shoppingInterest.map((interest) => {
                cat = get(interest, "category/descriptiveName");
                categories.push(cat);
              });
            }

            const ads = [];
            let userAds = get(user, "user/userAds");
            if (userAds) {
              userAds.map((userAd) => {
                let watched = get(userAd, "userAd/watched");
                let name = get(userAd, ["userAd/ad", "ad/name"]);
                ads.push({ name: name, watched: watched });
              });
            }

            const date = get(user, "user/dateOfBirth", "");

            return (
              <tr>
                <td>{get(user, "user/firstName", "")}</td>
                <td>{get(user, "user/lastName", "")}</td>
                <td>{get(user, "user/handle", "")}</td>
                <td>
                  {get(user, "user/shoppingInterests", []).map((cat) => (
                    <p>{get(cat, "category/descriptiveName")}</p>
                  ))}
                </td>
                <td>
                  balance: {get(user, ["user/wallet", "wallet/balance"], "")}, earnings:{" "}
                  {get(user, ["user/wallet", "wallet/earnings"])}
                </td>
                <td>{get(user, "user/applied", "") && <CheckIcon />}</td>
                <td>
                  {get(user, "user/appliedDate", "") &&
                    new Date(get(user, "user/appliedDate", "")).toDateString()}
                </td>
                <td>{get(user, "user/cellPhone", "")}</td>
                <td>{date && new Date(date).toDateString()}</td>
                <td>{JSON.stringify(ads)}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

const Companies = (props) => {
  return (
    <div style={{ padding: "10px 50px" }}>
      <Table striped bordered hover style={{ tableLayout: "fixed", wordBreak: "break-word" }}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Phone Number</th>
            <th>Address</th>
            <th>Tags</th>
            <th>Ads</th>
          </tr>
        </thead>
        <tbody>
          {props.companies.map((company) => {
            return (
              <tr>
                <td>{get(company, "company/name")}</td>
                <td>{get(company, "company/phoneNumber")}</td>
                <td>{get(company, "company/address")}</td>
                <td>{JSON.stringify(get(company, "company/tags"))}</td>
                <td>{JSON.stringify(get(company, "ad/_company"))}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

const Ads = (props) => {
  const [show, setShow] = useState(false);
  const [delAd, setDelAd] = useState(null);
  const cancelHandler = () => {
    setDelAd(null);
    setShow(false);
  };

  const clickDelete = (id) => {
    setDelAd(id);
    setShow(true);
  };

  const confirmDelete = () => {
    console.log(delAd);
    const tx = [{ _id: delAd, _action: "delete" }];
    const token = getToken();
    trackPromise(
      flureeFetch("transact", tx, token)
        .then((res) => {
          debugger;
          setShow(false);
          props.refresh();
        })
        .catch((err) => {
          debugger;
          setShow(false);
          console.log(err);
        })
    );
  };
  return (
    // <>
    //   <Modal show={show} onHide={handleClose} centered aria-labelledby="modal-title">
    //     <Modal.Header>
    //       <Modal.Title id="modal-title">Delete Ad?</Modal.Title>
    //     </Modal.Header>
    //     <Modal.Body>Are you sure you want to delete this ad?</Modal.Body>
    //     <Modal.Footer>
    //       <Button variant="secondary" onClick={handleClose}>
    //         Cancel
    //       </Button>
    //       <Button variant="primary" onClick={() => console.log(delAd)}>
    //         Yes, Delete
    //       </Button>
    //     </Modal.Footer>
    //   </Modal>

    <div style={{ padding: "10px 50px" }}>
      <Dialog
        aria-labelledby="delete-confirmation-title"
        aria-describedby="delete-confirmation-description"
        open={show}
      >
        <DialogTitle id="delete-confirmation-title">Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-confirmation-description">
            Once you delete this ad, it's gone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MuiButton color="primary" onClick={cancelHandler}>
            No
          </MuiButton>
          <MuiButton color="error" onClick={confirmDelete}>
            Delete
          </MuiButton>
        </DialogActions>
      </Dialog>
      <Table striped bordered hover style={{ tableLayout: "fixed", wordBreak: "break-word" }}>
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Tagline</th>
            <th>Cashtag</th>
            <th>Company Name</th>
            <th>Categories</th>
          </tr>
        </thead>
        <tbody>
          {props.ads && Array.isArray(props.ads)
            ? props.ads.map((ad) => {
                return (
                  <tr key={ad._id}>
                    <td>{get(ad, "ad/id")}</td>
                    <td>{get(ad, "ad/name")}</td>
                    <td>{get(ad, "ad/tagline")}</td>
                    <td>{get(ad, "ad/cashtag")}</td>
                    <td>{get(ad, ["ad/company", "company/name"])}</td>
                    <td>
                      {get(ad, "ad/categories")?.map((cat) => (
                        <p>{get(cat, "category/descriptiveName")}</p>
                      )) ?? null}
                    </td>
                    <td>
                      <Button onClick={() => clickDelete(ad._id)}>Delete</Button>
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </Table>
    </div>
    // </>
  );
};

class AdminConsole extends Component {
  state = {
    users: [],
    showUsers: false,
    handles: [],
    showHandles: false,
    companies: [],
    showCompanies: false,
    ads: [],
    showAds: false,
    showReset: false,
    resetMessage: "",
  };

  resetDemo = () => {
    this.setState({ resetMessage: "Deleting all ads, userAds, users, and companies." });

    const resetQuery = {
      userAds: { select: "?userAds", where: [["?user", "user/userAds", "?userAds"]] },
      users: { select: "?user", where: [["?user", "user/handle", "?handle"]] },
      companies: { select: "?company", where: [["?company", "company/name", "?name"]] },
      ads: { select: "?ad", where: [["?ad", "ad/id", "?id"]] },
      // "roles": { "select": "?role", "where": [["?role", "_role/id", "?roleId"]]}
    };
    const token = getToken();
    flureeFetch("multi-query", resetQuery, token)
      .then((res) => {
        let { userAds, users, ads, companies, roles } = res;
        let _idsToDelete = userAds.concat(users).concat(ads).concat(companies); //.concat(roles)
        let deleteTx = [];
        _idsToDelete.map((_id) => deleteTx.push({ _id: _id, _action: "delete" }));
        return deleteTx;
      })
      .then((res) => {
        if (res.length !== 0) {
          flureeFetch("/transact", res, token);
        }
      })
      .then((res) =>
        this.setState({ resetMessage: "Successfully deleted subjects. Adding default users." })
      )
      .then((res) => {
        const { privateKey, publicKey } = generateKeyPair();
        window.alert(
          `!!! IMPORTANT !!!\n\nYour new admin account has the following private and public keys\n\nPRIVATE KEY: ${privateKey}\nPUBLIC KEY: ${publicKey}\n\nIt is important that you store this data in a secure location.`
        );
        const authId = getSinFromPublicKey(publicKey);
        const userTx = generateUserTx(authId);
        return flureeFetch("/transact", userTx, token);
      })
      // .then(res => this.setState({ resetMessage: "Successfully added users. Creating base wallet transactions."}))
      // .then(res => flureeFetch("/transact", tx1))
      // .then(res => flureeFetch("/transact", tx2))
      // .then(res => flureeFetch("/transact", tx3))
      // .then(res => flureeFetch("/transact", tx4))
      // .then(res => flureeFetch("/transact", tx5))
      // .then(res => flureeFetch("/transact", tx6))
      // .then(res => flureeFetch("/transact", tx7))
      // .then(res => this.setState({ resetMessage: "Successfully added base wallet transactions. Adding default ads."}))
      .then((res) => flureeFetch("/transact", adTx, token))
      .then((res) => this.setState({ resetMessage: "Successfully reset demo." }))
      .catch((err) => this.setState({ error: err }));
  };

  responseGoogle = (response) => {
    let googleAccountId = response.googleId;

    if (googleAccountId) {
      let googleQuery = {
        selectOne: "?handle",
        where: [
          ["?user", "user/handle", "?handle"],
          ["?user", "user/googleId", googleAccountId],
        ],
      };
      const token = getToken();
      flureeFetch("query", googleQuery, token)
        .then((res) => {
          if (res === null) {
            this.setState({
              error: { message: "There is no user associated with this Google account" },
            });
          } else {
            localStorage.setItem("fabricUser", res);
            this.props.refreshUser();
          }
        })
        .catch((err) =>
          this.setState({
            error: {
              message:
                "Logging in with Google failed. Please retry. Response: " + JSON.stringify(err),
            },
          })
        );
    } else {
      this.setState({
        error: {
          message:
            "Logging in with Google failed. Please retry. Response: " + JSON.stringify(response),
        },
      });
    }
  };

  responseFacebook = (response) => {
    let facebookID = response.userID;

    if (facebookID) {
      let facebookQuery = {
        selectOne: "?handle",
        where: [
          ["?user", "user/handle", "?handle"],
          ["?user", "user/facebookId", facebookID],
        ],
      };
      const token = getToken();
      flureeFetch("query", facebookQuery, token)
        .then((res) => {
          if (res === null) {
            this.setState({
              error: { message: "There is no user associated with this Facebook account" },
            });
          } else {
            localStorage.setItem("fabricUser", res);
            this.props.refreshUser();
          }
        })
        .catch((err) =>
          this.setState({
            error: {
              message:
                "Logging in with Facebook failed. Please retry. Response: " + JSON.stringify(err),
            },
          })
        );
    } else {
      this.setState({
        error: {
          message:
            "Logging in with Facebook failed. Please retry. Response: " + JSON.stringify(response),
        },
      });
    }
  };

  showUsers() {
    // const userQuery = { "select": {"?user": [ "*",
    //   {"user/shoppingInterests": ["category/descriptiveName"]},
    //   {"user/userAds": ["userAd/watched", {"userAd/ad": ["ad/name"]}]}]},
    //   "where": [["?user", "user/registered", "?registered", {"filter": "(and ?registered)"}]] }

    const userQuery = {
      select: [
        "*",
        { "user/shoppingInterests": ["category/descriptiveName"] },
        { "user/userAds": ["userAd/watched", { "userAd/ad": ["ad/name"] }] },
      ],
      from: "user",
    };
    const token = getToken();
    flureeFetch("query", userQuery, token)
      .then((res) => {
        let newState = {
          showUsers: true,
          showHandles: false,
          showCompanies: false,
          showAds: false,
          showReset: false,
          error: null,
          refreshMessage: "",
        };
        if (res === undefined) {
          newState["users"] = [];
        } else {
          newState["users"] = res;
        }
        this.setState(newState);
      })
      .catch((err) => this.setState({ err: err }));
  }

  showHandles() {
    // const userQuery = { "select": "?handle",
    // "where": [["?user", "user/registered", "?registered", {"filter": "(and ?registered)"}],
    // ["?user", "user/handle", "?handle"]]}

    const userQuery = { select: "?handle", where: [["?user", "user/handle", "?handle"]] };
    const token = getToken();
    flureeFetch("query", userQuery, token)
      .then((res) => {
        let newState = {
          showHandles: true,
          showUsers: false,
          showCompanies: false,
          showAds: false,
          showReset: false,
          error: null,
          refreshMessage: "",
        };
        if (res === undefined) {
          newState["handles"] = [];
        } else {
          newState["handles"] = res;
        }
        this.setState(newState);
      })
      .catch((err) => this.setState({ err: err }));
  }

  showCompanies() {
    const companyQuery = {
      select: ["*", { "ad/_company": ["ad/id", "ad/name"] }],
      from: "company",
    };
    const token = getToken();
    flureeFetch("query", companyQuery, token)
      .then((res) => {
        let newState = {
          showCompanies: true,
          showHandles: false,
          showUsers: false,
          showAds: false,
          showReset: false,
          error: null,
          refreshMessage: "",
        };
        if (res === undefined) {
          newState["companies"] = [];
        } else {
          newState["companies"] = res;
        }
        this.setState(newState);
      })
      .catch((err) => this.setState({ error: err }));
  }

  showAds() {
    const adQuery = {
      select: [
        "ad/id",
        "ad/name",
        "ad/tagline",
        "ad/cashtag",
        { "ad/categories": ["category/descriptiveName"] },
        { "ad/company": ["company/name"] },
      ],
      from: "ad",
    };
    const token = getToken();
    flureeFetch("query", adQuery, token)
      .then((res) => {
        let newState = {
          showAds: true,
          showCompanies: false,
          showHandles: false,
          showUsers: false,
          showReset: false,
          error: null,
          refreshMessage: "",
        };
        if (res === undefined) {
          newState["ads"] = [];
        } else {
          newState["ads"] = res;
        }
        this.setState(newState);
      })
      .catch((err) => this.setState({ err: err }));
  }

  showReset() {
    this.setState({
      showReset: true,
      showAds: false,
      showCompanies: false,
      showHandles: false,
      showUsers: false,
      error: null,
      refreshMessage: "",
    });
  }

  selectUser(e) {
    if (this.state.handles.includes(e)) {
      localStorage.setItem("fabricUser", e);
      this.props.refreshUser();
    } else {
      this.setState({ error: { message: "There is no user associated with this handle." } });
    }
  }

  render() {
    const headers = [
      { label: "First Name", key: "user/firstName" },
      { label: "Last Name", key: "user/lastName" },
      { label: "Email", key: "user/email" },
      { label: "Handle", key: "user/handle" },
      { label: "Zip Code", key: "user/zipcode" },
      { label: "Gender", key: "user/gender" },
      { label: "Gender (continued)", key: "user/genderSpec" },
      { label: "Phone Number", key: "user/cellPhone" },
      { label: "Wallet Balance", key: "user/wallet[wallet/balance]" },
      {
        label: "Shopping Interests #1",
        key: "user/shoppingInterests[0][category/descriptiveName]",
      },
      {
        label: "Shopping Interests #2",
        key: "user/shoppingInterests[1][category/descriptiveName]",
      },
      {
        label: "Shopping Interests #3",
        key: "user/shoppingInterests[2][category/descriptiveName]",
      },
      {
        label: "Shopping Interests #4",
        key: "user/shoppingInterests[3][category/descriptiveName]",
      },
      {
        label: "Shopping Interests #5",
        key: "user/shoppingInterests[4][category/descriptiveName]",
      },
    ];
    return (
      <div>
        {/* <div className="row">
              <div className="col-xs-3" style={{height: "100px"}}>
                <a className="navbar-brand" href="index.html" style={{paddingTop: 3, paddingLeft: 15}}>
                  <img src={require("../assets/img/fabric_logo_lg.png")} height="55" alt="We are Fabric" />
                </a>
              </div>
          </div> */}
        <div className="row" style={{ marginTop: "100px" }}>
          <div className="col-xs-12 text-center" style={{ marginBottom: "20px" }}>
            <h3>Admin Console</h3>
          </div>
        </div>
        <div className="row" style={{ marginBottom: "20px" }}>
          <div className="col-sm-2" />
          {/* <div className="col-sm-2 text-center" >
              <button className="admin-button admin-button-grey" onClick={() => this.showHandles()}>
                <p>Sign-in&nbsp;<i className="fas fa-sign-in-alt"></i></p>
              </button>
            </div> */}
          <div className="col-sm-2 text-center">
            <button className="admin-button admin-button-grey" onClick={() => this.showUsers()}>
              <p>
                Users&nbsp;<i className="fas fa-users"></i>
              </p>
            </button>
          </div>
          <div className="col-sm-2 text-center">
            <button className="admin-button admin-button-grey" onClick={() => this.showCompanies()}>
              <p>
                Companies&nbsp;<i className="fas fa-city"></i>
              </p>
            </button>
          </div>
          <div className="col-sm-2 text-center">
            <button className="admin-button admin-button-grey" onClick={() => this.showAds()}>
              <p>
                Ads&nbsp;<i className="fas fa-video"></i>
              </p>
            </button>
          </div>
          <div className="col-sm-2 text-center">
            <button className="admin-button admin-button-grey" onClick={() => this.showReset()}>
              <p>
                Reset&nbsp;<i className="fas fa-video"></i>
              </p>
            </button>
          </div>
        </div>
        <div className="row">
          {this.state.error ? (
            <div className="col-xs-12 text-center">
              <h4 style={{ color: "red", padding: "20px" }}>{this.state.error.message}</h4>
            </div>
          ) : null}
          {this.state.showHandles ? (
            <div>
              <div className="col-xs-1" />
              <div
                className="col-xs-4 text-center"
                style={{ border: "1px solid lightgrey", padding: "50px", marginLeft: "30px" }}
              >
                <h4>For Demo Purposes:</h4>
                <p>Sign-In By Selecting a Handle or normal sign-in below</p>
                <select
                  defaultValue="Select a User"
                  value={this.state.user}
                  onChange={(e) => this.selectUser(e.target.value)}
                  style={{ fontSize: "16px", width: "250px" }}
                >
                  <option value="Select a User" disabled>
                    Select a User
                  </option>
                  {this.state.handles.map((user) => (
                    <option key={user} value={user}>
                      {user}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-xs-1" />
              <div className="col-xs-4">
                <h3 className="text-center">Sign-In</h3>
                <Form>
                  <Form.Group controlId="email">
                    <Form.Label>Handle</Form.Label>
                    <Form.Control
                      type="string"
                      placeholder="Handle"
                      value={this.state.user}
                      onChange={(e) => this.setState({ user: e.target.value })}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      value={this.state.password}
                      disabled
                    ></Form.Control>
                  </Form.Group>
                  <div className="text-center">
                    <Button
                      onClick={() => this.selectUser(this.state.user)}
                      disabled={!this.state.user}
                    >
                      Login
                    </Button>
                  </div>
                </Form>
                <div style={{ padding: "20px" }}>
                  <h2
                    className="text-center"
                    style={{
                      width: "100%",
                      borderBottom: "1px solid #000",
                      lineHeight: "0.1em",
                      margin: "10px 0 20px",
                    }}
                  >
                    <span style={{ background: "#FFF", padding: "0 10px" }}>or</span>
                  </h2>
                </div>
              </div>
              {/* </div> */}
              <div className="row">
                <div className="col-xs-6" />
                <div className="col-xs-4 text-center">
                  <GoogleLogin
                    clientId="504403604147-tbmb49cfc3qum3o4i2i8bf3fq0tcur4n.apps.googleusercontent.com"
                    onSuccess={this.responseGoogle}
                    onFailure={this.responseGoogle}
                    cookiePolicy={"single_host_origin"}
                    render={(renderProps) => (
                      <button className="loginBtn loginBtn--google" onClick={renderProps.onClick}>
                        Login with Google
                      </button>
                    )}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-6" />
                <div className="col-xs-4 text-center">
                  <FacebookLogin
                    appId="1196010743891686"
                    autoLoad={false}
                    fields="name,email,picture"
                    callback={this.responseFacebook}
                    render={(renderProps) => (
                      <button className="loginBtn loginBtn--facebook" onClick={renderProps.onClick}>
                        Login with Facebook
                      </button>
                    )}
                  />
                </div>
              </div>
            </div>
          ) : null}
          {this.state.showUsers ? (
            <div>
              <div className="row">
                <div className="col-md-9 col-xs-6"></div>
                <div className="col-md-3 col-xs-6 text-left">
                  <CSVLink
                    className="btn btn-default"
                    target="_blank"
                    filename={"users-export.csv"}
                    data={this.state.users}
                    headers={headers}
                    style={{ marginRight: "6px" }}
                  >
                    <i className="fas fa-user-plus" />
                    &nbsp;&nbsp;Export Users
                  </CSVLink>
                  {/* <Button style={{marginRight: "6px"}}
                        onClick={(e) => {
                          return(
                            <CSVDownload data={this.state.users} target="_blank" />
                          )
                        }}> */}
                  <Button
                    style={{ marginRight: "6px" }}
                    onClick={() => this.props.history.push("/register")}
                  >
                    <i className="fas fa-user-plus" />
                    &nbsp;&nbsp;Add User
                  </Button>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 text-center">
                  <Users users={this.state.users} />
                </div>
              </div>
            </div>
          ) : null}
          {this.state.showCompanies ? (
            <div>
              <div className="row">
                <div className="col-xs-9" />
                <div className="col-xs-2 text-right">
                  <Button
                    style={{ marginRight: "6px" }}
                    onClick={() => this.props.history.push("/company")}
                  >
                    <i className="fas fa-user-plus" />
                    &nbsp;&nbsp;Add Company
                  </Button>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 text-center">
                  <Companies companies={this.state.companies} />
                </div>
              </div>
            </div>
          ) : null}
          {this.state.showAds ? (
            <div>
              <div className="row">
                <div className="col-xs-9" />
                <div className="col-xs-2 text-right">
                  <Button
                    style={{ marginRight: "6px" }}
                    onClick={() => this.props.history.push("/ad")}
                  >
                    <i className="fas fa-user-plus" />
                    &nbsp;&nbsp;Add Ad
                  </Button>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 text-center">
                  <Ads ads={this.state.ads} refresh={this.showAds.bind(this)} />
                </div>
              </div>
            </div>
          ) : null}
          {/* {this.state.showReset ? (
            <div className="text-center" style={{ marginTop: "40px" }}>
              <p>{this.state.resetMessage ? <p>{this.state.resetMessage}</p> : null}</p>
              <p>
                <Button onClick={() => this.resetDemo()}>Reset Demo</Button>
              </p>
            </div>
          ) : null} */}
        </div>
      </div>
    );
  }
}

class AdminRouting extends Component {
  state = {};

  render() {
    const { refreshUser, user } = this.props;
    return (
      <div style={{ minHeight: "100vh" }}>
        <LoadingSpinner />
        <BrowserRouter>
          <Switch>
            <Route
              path="/register"
              render={(props) => (
                <>
                  <Navigation user={user} refreshUser={refreshUser} />
                  <Register {...props} />
                </>
              )}
            />
            <Route
              path="/company"
              render={(props) => (
                <>
                  <Navigation user={user} refreshUser={refreshUser} />
                  <AddCompany {...props} {...this.props} />
                </>
              )}
            />
            <Route
              path="/ad"
              render={(props) => (
                <>
                  <Navigation user={user} refreshUser={refreshUser} />
                  <AddAd {...props} {...this.props} />
                </>
              )}
            />
            <Route
              path="/"
              render={(props) => (
                <>
                  <Navigation user={user} refreshUser={refreshUser} />
                  <AdminConsole refreshUser={this.props.refreshUser} {...props} />
                </>
              )}
            />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default AdminRouting;

import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Divider } from "@material-ui/core";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import get from "lodash.get";
import UserContext from "../contexts/UserContext";
import { formatNumber as fN } from '../utils/functions'

const useStyles = makeStyles(theme => ({
  title: {
    [theme.breakpoints.down('sm')]: {
      margin: '0 20px'
    }
  },
  root: {
    borderRadius: 5,
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      margin: "0 20px"
    },
    "&:last-child": {
      paddingBottom: 0
    }
  },
  content: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',

  },
  inner: {
    width: "100%",
    textAlign: "center"
  },
  header: {
    textAlign: "center",
    color: '#009444',
    marginTop: 20
  },
  subheader: {
    textTransform: "none",
    color: theme.palette.text.primary,
    marginBottom: 10
    //fontSize: theme.typography.pxToRem(12)
  },
  content: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(12),
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
    "&:last-child": {
      paddingBottom: 16
    }
  },
  dataContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}));

function TransactionItem({ amount, text }) {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const walletBalance = get(user, ["user/wallet", "wallet/balance"]);
  const [socialCashAmt, setSocialCashAmt] = useState(walletBalance);

  return (
    <>
      <div className={classes.title}>
        <Typography variant='button'>{text}</Typography>
      </div>
      <Card className={classes.root}>
        <CardContent className={classes.content}>
          <div className={classes.dataContainer}>
            <Typography variant="h1" className={classes.header}>
              {fN(amount)}
            </Typography>
            <Typography variant="body1" className={classes.subheader}>
              CA$H
          </Typography>
            {/* <Typography variant="subtitle2" className={classes.content}>
            {text}
          </Typography> */}
          </div>
          <Divider orientation="vertical" flexItem style={{ backgroundColor: '#707070' }} />
          <div className={classes.dataContainer}>
            <Typography variant="h1" className={classes.header}>
              ${fN((amount / 100))}
            </Typography>
            <Typography variant="body1" className={classes.subheader}>
              USD
          </Typography>
          </div>
        </CardContent>
      </Card>
    </>
  );
}

TransactionItem.propTypes = {
  amount: PropTypes.string,
  text: PropTypes.string
};

export default TransactionItem;

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Container,
  Grid,
  Card,
  CardContent,
  Box,
  Divider,
  Hidden
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import UserInfo from '../components/UserInfo';
import FinancialIntegration from '../components/FinancialIntegration';
import ResetPassword from '../components/ResetPassword';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3)
    },
    paddingBottom: theme.spacing(3),
    background: '#FFFFFF',
    width: '100%'
  },
  grid: {
    marginTop: theme.spacing(2)
  },
  title: {
    color: theme.palette.text.primary
  },
  card: {
    borderRadius: 20
  },
  horizontalDividerGrid: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: 20
  },
  horizontalDivider: {
    backgroundColor: '#D0EFFC',
    height: 3,
    width: '90%'
  },
  vertDividerGrid: {
    display: 'flex',
    justifyContent: 'center'
  },
  vertDivider: {
    width: 3,
    backgroundColor: '#D0EFFC'
  }
}));

function Profile({ refreshUser, user, blockData, beginLoading, endLoading }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth={true}>
        <Typography variant="h1" className={classes.title}>
          Smart Profile
        </Typography>

        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            <Box className={classes.card}>
              {' '}
              {/* <CardContent className={classes.content}> */}
              <UserInfo
                refreshUser={refreshUser}
                user={user}
                blockData={blockData}
                beginLoading={beginLoading}
                endLoading={endLoading}
              ></UserInfo>
              {/* </CardContent> */}
            </Box>
          </Grid>
          <Grid item xs={12} className={classes.horizontalDividerGrid}>
            <Divider className={classes.horizontalDivider} />
          </Grid>
          <Grid item lg={5}  xs={12}>
            <FinancialIntegration></FinancialIntegration>
          </Grid>
          <Hidden mdDown>
            <Grid item lg={1} xl={1} className={classes.vertDividerGrid}>
              <Divider orientation="vertical" className={classes.vertDivider} />
            </Grid>
          </Hidden>
          <Hidden lgUp>
            <Grid item xs={12} className={classes.horizontalDividerGrid}>
              <Divider className={classes.horizontalDivider} />
            </Grid>
          </Hidden>
          <Grid item lg={4} xs={12}>
            <ResetPassword></ResetPassword>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Profile;

export default {
    big: {
        h1: 72,
        h2: 56,
        subtext: 40,
        p1: 24,
        button: 40,
        alert: 24,
        input: 24
    },
    small: {
        h1: 28,
        h2: 24,
        subtext: 16,
        p1: 10,
        button: 14,
        alert: 12,
        input: 12
    },
    med: {
        h1: 32,
        h2: 24,
        subtext: 16,
        p1: 12,
        button: 16,
        alert: 12,
        input: 12
    }
}
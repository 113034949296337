import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Card, CardHeader, CardContent, Typography } from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import Chart from "./Chart";
import { formatNumber as fN } from '../../utils/functions'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 20,
    display: "flex",
    height: 300
  },
  contentHeader: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingTop: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(5)
  },
  content: {
    flex: 3,
    height: "100%",
    "&:last-child": {
      paddingBottom: 0
    }
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginLeft: theme.spacing(1)
    }
  },
  inner: {
    height: "100%"
    //minWidth: 250
  },
  chart: {
    height: "100%",
  },
  scrollbar: {
    height: '100%'
  }
}));

const data = {
  thisWeek: {
    data: [],
    labels: []
  },
  thisMonth: {
    data: [],
    labels: []
  },
  thisYear: {
    data: [1000, 500, 4500, 2000, 7500, 2800, 1800, 6000, 1300, 10000, 1300, 1550],
    labels: ["May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Jan", "Feb", "Mar", "Apr"]
  }
};

function EarningHistory({ className, blockData, ...rest }) {
  const classes = useStyles();
  const [total, setTotal] = useState(data.thisYear.data.slice(8, 12).reduce((a, c) => a + c), 0)
  const blockOneDate = moment(blockData['_block/instant'])

  return (
    <Card {...rest} className={classes.root}>
      <CardContent className={classes.contentHeader}>
        <div>
          <Typography variant="h3">Earnings History</Typography>
          <Typography variant="body1">
            You earned the most CA$H in February! Keep it up to earn even more!
          </Typography>
        </div>
        <div>
          <Typography variant="h3">{fN(total)} CA$H</Typography>
          <Typography variant="body1">Total CA$H earned in 2020!</Typography>
        </div>
      </CardContent>
      <CardContent className={classes.content}>
        <PerfectScrollbar className={classes.scrollbar}>
          <div className={classes.inner}>
            <Chart
              className={classes.chart}
              data={data.thisYear.data}
              labels={data.thisYear.labels}
            />
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
}

EarningHistory.propTypes = {
  className: PropTypes.string
};

export default EarningHistory;

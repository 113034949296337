export default [
  {
    _id: "_collection",
    name: "user"
  },
  {
    _id: "_collection",
    name: "ad"
  },
  {
    _id: "_collection",
    name: "userAd"
  },
  {
    _id: "_collection",
    name: "company"
  },
  {
    _id: "_collection",
    name: "product"
  },
  {
    _id: "_collection",
    name: "wallet"
  },
  {
    _id: "_collection",
    name: "purchase"
  },
  {
    _id: "_collection",
    name: "category"
  },
  {
    _id: "_collection",
    name: "post"
  },
  {
    _id: "_predicate",
    name: "user/email",
    type: "string",
    unique: true,
    spec: [
      {
        _id: "_fn",
        code: "(valid-email? (?o))",
        doc: "Is object a valid email?"
      }
    ],
    specDoc: "Is the object a valid email?"
  },
  {
    _id: "_predicate",
    name: "user/firstName",
    type: "string"
  },
  {
    _id: "_predicate",
    name: "user/lastName",
    type: "string"
  },
  {
    _id: "_predicate",
    name: "user/cellPhone",
    type: "string"
  },
  {
    _id: "_predicate",
    name: "user/gender",
    type: "tag"
  },
  {
    _id: "_predicate",
    name: "user/zipcode",
    type: "string"
  },
  {
    _id: "_predicate",
    name: "user/shoppingInterests",
    type: "ref",
    restrictCollection: "category",
    multi: true
  },
  {
    _id: "_predicate",
    name: "user/profilePic",
    type: "string"
  },
  {
    _id: "_predicate",
    name: "user/handle",
    type: "string",
    unique: true
  },
  {
    _id: "_predicate",
    name: "user/googleId",
    type: "string",
    unique: true
  },
  {
    _id: "_predicate",
    name: "user/facebookId",
    type: "string",
    unique: true
  },
  {
    _id: "_predicate",
    name: "user/twitterId",
    type: "string",
    unique: true
  },
  {
    _id: "_predicate",
    name: "user/twitterHandle",
    type: "string",
    unique: true
  },
  {
    _id: "_predicate",
    name: "user/password",
    type: "string"
  },
  {
    _id: "_predicate",
    name: "user/dateOfBirth",
    type: "instant"
  },
  {
    _id: "_predicate",
    name: "user/purchases",
    type: "ref",
    restrictCollection: "purchase",
    multi: true,
    component: true
  },
  {
    _id: "_predicate",
    name: "user/userAds",
    type: "ref",
    restrictCollection: "userAd",
    component: true,
    multi: true
  },
  {
    _id: "_predicate",
    name: "user/wallet",
    type: "ref",
    restrictCollection: "wallet",
    unique: true,
    component: true
  },
  {
    _id: "_predicate",
    name: "user/registered",
    type: "boolean"
  },
  {
    _id: "_predicate",
    name: "user/adminAuth",
    type: "ref",
    restrictCollection: "_auth",
    multi: false,
    component: true
  },
  {
    _id: "_predicate",
    name: "userAd/id",
    type: "int",
    unique: true,
    doc: "Unique id for userAd"
  },
  {
    _id: "_predicate",
    name: "userAd/ad",
    type: "ref",
    restrictCollection: "ad"
  },
  {
    _id: "_predicate",
    name: "userAd/watchDate",
    doc: "The instant when the user finished watching the ad.",
    type: "instant"
  },
  {
    _id: "_predicate",
    name: "userAd/watched",
    doc: "Whether the video has been watched",
    type: "boolean"
  },
  {
    _id: "_predicate",
    name: "userAd/questionStatus",
    type: "tag"
  },
  {
    _id: "_predicate",
    name: "userAd/pointsEarned",
    type: "int"
  },
  {
    _id: "_predicate",
    name: "user/posts",
    type: "ref",
    restrictCollection: "post",
    component: true,
    multi: true
  },
  {
    _id: "_predicate",
    name: "post/caption",
    type: "string"
  },
  {
    _id: "_predicate",
    name: "post/cashtag",
    type: "ref",
    restrictCollection: "_tag"
  },
  {
    _id: "_predicate",
    name: "post/imageURL",
    type: "string"
  },
  {
    _id: "_predicate",
    name: "post/users",
    type: "ref",
    restrictCollection: "user",
    multi: true
  },
  {
    _id: "_predicate",
    name: "post/location",
    type: "string"
  },
  {
    _id: "_predicate",
    name: "post/sharedOnFacebook",
    type: "boolean"
  },
  {
    _id: "_predicate",
    name: "post/sharedOnInstagram",
    type: "boolean"
  },
  {
    _id: "_predicate",
    name: "post/sharedOnTwitter",
    type: "boolean"
  },
  {
    _id: "_predicate",
    name: "ad/name",
    type: "string"
  },
  {
    _id: "_predicate",
    name: "ad/video",
    type: "string",
    doc: "Link to the video."
  },
  {
    _id: "_predicate",
    name: "ad/coverImage",
    type: "string",
    doc: "Link to the image."
  },
  {
    _id: "_predicate",
    name: "ad/thumbnail",
    type: "string",
    doc: "Link to the thumbnail for the ad."
  },
  {
    _id: "_predicate",
    name: "ad/id",
    type: "int",
    unique: true
  },
  {
    _id: "_predicate",
    name: "ad/company",
    type: "ref",
    restrictCollection: "company"
  },
  {
    _id: "_predicate",
    name: "ad/products",
    type: "ref",
    restrictCollection: "product",
    multi: true
  },
  {
    _id: "_predicate",
    name: "ad/question",
    type: "string"
  },
  {
    _id: "_predicate",
    name: "ad/answerChoices",
    type: "string",
    multi: true
  },
  {
    _id: "_predicate",
    name: "ad/correctAnswer",
    type: "string"
  },
  {
    _id: "_predicate",
    name: "ad/videoPoints",
    type: "int"
  },
  {
    _id: "_predicate",
    name: "ad/questionPoints",
    type: "int"
  },
  {
    _id: "_predicate",
    name: "ad/bonusQuestion",
    type: "string"
  },
  {
    _id: "_predicate",
    name: "ad/bonusQuestionPoints",
    type: "int"
  },
  {
    _id: "_predicate",
    name: "ad/bonusQuestionAnswerChoices",
    type: "string",
    multi: true
  },
  {
    _id: "_predicate",
    name: "ad/bonusQuestionCorrectAnswer",
    type: "string"
  },
  {
    _id: "_predicate",
    name: "ad/categories",
    type: "ref",
    restrictCollection: "category",
    multi: true,
    doc: "Describe the category of the product. Used for matching to user."
  },
  {
    _id: "_predicate",
    name: "ad/tagline",
    type: "string",
    doc: "Tagline to display below video"
  },
  {
    _id: "_predicate",
    name: "ad/cashtag",
    type: "tag",
    doc: "$tag to display below video"
  },
  {
    _id: "_predicate",
    name: "ad/budget",
    type: "int",
    index: true
  },
  {
    _id: "_predicate",
    name: "ad/budgetRemaining",
    type: "int",
    index: true
  },
  {
    _id: "_predicate",
    name: "ad/finished",
    type: "boolean"
  },
  {
    _id: "_predicate",
    name: "company/name",
    type: "string",
    unique: true
  },
  {
    _id: "_predicate",
    name: "company/phoneNumber",
    type: "string",
    unique: true
  },
  {
    _id: "_predicate",
    name: "company/address",
    type: "string"
  },
  {
    _id: "_predicate",
    name: "company/tags",
    doc: "Tags that describe the company's products and services",
    type: "tag",
    multi: true
  },
  {
    _id: "_predicate",
    name: "company/logo",
    doc: "URL for company/logo",
    type: "string"
  },
  {
    _id: "_predicate",
    name: "product/name",
    type: "string"
  },
  {
    _id: "_predicate",
    name: "product/id",
    type: "string",
    unique: true
  },
  {
    _id: "_predicate",
    name: "product/company",
    type: "ref",
    restrictCollection: "company"
  },
  {
    _id: "_predicate",
    name: "product/description",
    type: "string"
  },
  {
    _id: "_predicate",
    name: "product/cost",
    type: "int"
  },
  {
    _id: "_predicate",
    name: "product/categories",
    type: "ref",
    restrictCollection: "category",
    multi: true,
    doc: "The most specific categories that this product belongs to."
  },
  {
    _id: "_predicate",
    name: "wallet/name",
    type: "string",
    unique: true
  },
  {
    _id: "_predicate",
    name: "wallet/balance",
    type: "int"
  },
  {
    _id: "_predicate",
    name: "wallet/earnings",
    type: "int"
  },
  {
    _id: "_predicate",
    name: "purchase/category",
    type: "ref",
    restrictCollection: "category"
  },
  {
    _id: "_predicate",
    name: "purchase/priceRange",
    type: "tag"
  },
  {
    _id: "_predicate",
    name: "purchase/timeRange",
    type: "string"
  },
  {
    _id: "_predicate",
    name: "category/displayName",
    type: "string"
  },
  {
    _id: "_predicate",
    name: "category/descriptiveName",
    doc: "A longer name for this category to clear up potential ambiguity",
    type: "string",
    unique: true
  },
  {
    _id: "_predicate",
    name: "category/children",
    type: "ref",
    multi: true
  },
  {
    _id: "_predicate",
    name: "category/priceRanges",
    type: "string"
  },
  {
    _id: "_predicate",
    name: "userAd/sharedOnTwitter",
    type: "boolean"
  },
  {
    _id: "_predicate",
    name: "userAd/sharedOnInstagram",
    type: "boolean"
  },
  {
    _id: "_predicate",
    name: "userAd/sharedOnFacebook",
    type: "boolean"
  },
  {
    _id: "_predicate",
    name: "ad/postPoints",
    type: "int"
  },

  {
    _id: "_collection",
    name: "question"
  },
  {
    _id: "_predicate",
    name: "question/type",
    type: "tag"
  },
  {
    _id: "_predicate",
    name: "question/priority",
    type: "int"
  },
  {
    _id: "_predicate",
    name: "question/label",
    type: "string"
  },
  {
    _id: "_predicate",
    name: "question/numAnswers",
    type: "int"
  },
  {
    _id: "_predicate",
    name: "question/answerChoices",
    type: "string",
    multi: true
  },
  {
    _id: "_predicate",
    name: "question/correctAnswer",
    type: "string"
  },
  {
    _id: "_predicate",
    name: "question/answerPoints",
    type: "int"
  },
  {
    _id: "_predicate",
    name: "question/actionURI",
    type: "string"
  },

  {
    _id: "_predicate",
    name: "ad/questions",
    type: "ref",
    multi: true,
    restrictCollection: "question"
  },

  {
    _id: "_collection",
    name: "answer"
  },
  {
    _id: "_predicate",
    name: "answer/question",
    type: "ref",
    restrictCollection: "question"
  },
  {
    _id: "_predicate",
    name: "answer/answerChoice",
    type: "string"
  },
  {
    _id: "_predicate",
    name: "answer/answerValue",
    type: "int"
  },

  {
    _id: "_predicate",
    name: "userAd/questionFailed",
    type: "ref",
    restrictCollection: "answer"
  },
  {
    _id: "_predicate",
    name: "userAd/questionsSucceeded",
    type: "ref",
    multi: true,
    restrictCollection: "answer"
  },
  {
    _id: "_predicate",
    name: "userAd/consumerData",
    type: "ref",
    multi: true,
    restrictCollection: "answer"
  },

  {
    _id: "_collection",
    name: "plaid"
  },
  {
    _id: "_predicate",
    name: "plaid/accessToken",
    type: "string"
  },
  {
    _id: "_predicate",
    name: "user/plaid",
    type: "ref",
    restrictCollection: "plaid"
  }
];

import React, { Component } from "react";

import LeftSidebar from "../components/LeftSidebar";
import RightSidebar from "../components/RightSidebar";
import Wallet from "../components/account/Wallet";
import Requests from "../components/account/Requests";
import LevelUp from "../components/account/LevelUp";
import NavIcons from "../components/NavIcons";
import UpcomingPurchases from "../components/account/UpcomingPurchases";
import Settings from "../components/account/Settings";
import MobileTopNav from "../components/MobileTopNav";

import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import { Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { flureeFetch, getToken } from "../flureeFetch";
import Font from "../constants/FontSize";

import { s3Bucket } from "../appConfig";
import get from "lodash.get";

import notificationsImg from "../assets/img/dashboard/notifications.png";
import checkedTickImg from "../assets/img/dashboard/002-tick-1.png";
import uncheckedTickImg from "../assets/img/dashboard/001-tick.png";

class Account extends Component {
  state = {
    trendingInfluencers: [],
    subpage: "view-wallet",
    modalShow: false,
  };

  componentDidMount() {
    const profilePic = get(this.props.user, "user/profilePic");
    const profilePicPath = profilePic.match(/^http/)
      ? profilePic
      : `https://s3.amazonaws.com/${s3Bucket}/${profilePic}`;
    this.setState({ profilePic: profilePicPath });
    const subpage = this.props.match.params.subpage;
    const walletName = get(this.props, ["user", "user/wallet", "wallet/name"]);
    const blockOneDate = this.props.blockData["_block/instant"];
    const nowDate = new Date();
    const dayAgoDate = new Date().setDate(nowDate.getDate() - 1);
    const weekAgoDate = new Date().setDate(nowDate.getDate() - 7);
    const monthAgoDate = new Date().setMonth(nowDate.getMonth() - 1);
    this.getWalletStats(
      walletName,
      blockOneDate,
      dayAgoDate,
      weekAgoDate,
      monthAgoDate
    ).then((res) => this.setState({ socialCashAmt: this.state.balance }));

    // const trendingQuery = {
    //    "select": {
    //       "?user": [
    //          "*"
    //       ]
    //    },
    //    "where": [
    //       [
    //          "?user",
    //          "user/wallet",
    //          "?wallet"
    //       ],
    //       [
    //          "?wallet",
    //          "wallet/balance",
    //          "?balance",
    //          { "filter": "(> ?balance 50)" }
    //       ]
    //    ],
    //    "limit": 20
    // }

    // flureeFetch("/query", trendingQuery)
    //    .then(res => this.setState({ trendingInfluencers: res, subpage: subpage }))
    //    .then(res => {
    //       console.log("state:", this.state)
    //       console.log("props:", this.props)
    //       if (!this.timerHandle && (this.props.match.params.subpage === "newUser" || this.props.location.pathname === "/register")) {
    //          this.timerHandle = setTimeout(() => {
    //             console.log("inside setTimeout function")
    //             this.setState({ modalShow: true })
    //          }, 2000)
    //       }
    //    })
  }

  getWalletStats(walletName, blockOneDate, dayAgoDate, weekAgoDate, monthAgoDate) {
    const dayFlag = blockOneDate < dayAgoDate;
    const weekFlag = blockOneDate < weekAgoDate;
    const monthFlag = blockOneDate < monthAgoDate;
    const selectOne = ["?earnings", "?balance"];
    const where = [
      ["?wallet", "wallet/name", walletName],
      ["?wallet", "wallet/earnings", "?earnings"],
      ["?wallet", "wallet/balance", "?balance"],
    ];
    const firstWalletDate = new Date(blockOneDate + 1000000).toISOString();
    if (dayFlag) {
      selectOne.push("?earningsYesterday");
      where.push([
        "$fdbP1D",
        "?wallet",
        "wallet/earnings",
        "?earningsYesterday",
        { optional: true },
      ]);
    } else {
      selectOne.push("?earningsYesterday");
      where.push([
        "$fdb" + firstWalletDate,
        "?wallet",
        "wallet/earnings",
        "?earningsYesterday",
        { optional: true },
      ]);
    }
    if (weekFlag) {
      selectOne.push("?earningsLastWeek");
      where.push([
        "$fdbP1W",
        "?wallet",
        "wallet/earnings",
        "?earningsLastWeek",
        { optional: true },
      ]);
    } else {
      selectOne.push("?earningsLastWeek");
      where.push([
        "$fdb" + firstWalletDate,
        "?wallet",
        "wallet/earnings",
        "?earningsLastWeek",
        { optional: true },
      ]);
    }
    if (monthFlag) {
      selectOne.push("?earningsLastMonth");
      where.push([
        "$fdbP1M",
        "?wallet",
        "wallet/earnings",
        "?earningsLastMonth",
        { optional: true },
      ]);
    } else {
      selectOne.push("?earningsLastMonth");
      where.push([
        "$fdb" + firstWalletDate,
        "?wallet",
        "wallet/earnings",
        "?earningsLastMonth",
        { optional: true },
      ]);
    }
    const walletQuery = {
      selectOne: selectOne,
      where: where,
    };
    const token = getToken();
    return flureeFetch("query", walletQuery, token).then((res) => {
      const [earnings, balance, earningsYesterday, earningsLastWeek, earningsLastMonth] = res;
      const dayEarnings = earnings - (earningsYesterday || 0);
      const weekEarnings = earnings - (earningsLastWeek || 0);
      const monthEarnings = earnings - (earningsLastMonth || 0);
      this.setState({
        dayEarnings: dayEarnings,
        weekEarnings: weekEarnings,
        monthEarnings: monthEarnings,
        balance: balance,
      });
    });
  }

  // setModalShow = () => {
  //    this.setState(prevState => {
  //       return { modalShow: !prevState.modalShow }
  //    })
  // }

  componentDidUpdate() {
    if (this.state.subpage !== this.props.match.params.subpage) {
      this.setState({ subpage: this.props.match.params.subpage });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timerHandle);
  }

  render() {
    const { modalShow, dayEarnings, weekEarnings, monthEarnings, balance } = this.state;
    const twitterId = this.props.user["user/twitterId"];
    const watchedAd =
      this.props.user["user/userAds"] &&
      this.props.user["user/userAds"][0] &&
      !!this.props.user["user/userAds"][0]["userAd/watched"];
    const sharedAd =
      this.props.user["user/userAds"] &&
      this.props.user["user/userAds"][0] &&
      !!this.props.user["user/userAds"][0]["userAd/sharedOnTwitter"];
    const { innerWidth, toggleNav, innerHeight } = this.props;
    const lineHeight = (size) => String(1.3 * size) + "px";
    let fs = Font.big;
    fs = innerWidth < 768 ? Font.med : fs;
    fs = innerWidth < 350 ? Font.small : fs;
    if (innerWidth < 1025) {
      return (
        <div style={{ display: "flex", height: innerHeight, flexDirection: "column" }}>
          {/* This will become MobileTopNav component */}
          <MobileTopNav
            {...this.props}
            toggleNav={toggleNav}
            profilePic={this.state.profilePic}
            balance={this.props.user["user/wallet"]["wallet/balance"]}
          />

          <div
            style={{
              display: "flex",
              flex: 1,
              overflow: "scroll",
              flexDirection: "column",
              justifyContent: "flex-start",
              padding: "0 3vw",
              marginTop: "2vh",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                padding: "0 7vw 2vh 7vw",
                background: "#D5EFFC 0% 0% no-repeat padding-box",
                boxShadow: "0px 3px 6px #00000029",
                borderRadius: 30,
                opacity: 1,
              }}
            >
              <div
                style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    height: "10vh",
                    fontFamily: "PT Sans",
                    fontWeight: "bold",
                    fontSize: fs.h2,
                    lineHeight: lineHeight(fs.h2),
                    letterSpacing: 0,
                    color: "#282560",
                    opacity: 1,
                  }}
                >
                  Earnings History:
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "18vh",
                  }}
                >
                  <div
                    style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}
                  >
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        paddingRight: "4vw",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          textAlign: "end",
                          fontFamily: "PT Sans",
                          fontSize: fs.subtext,
                          lineHeight: lineHeight(fs.subtext),
                        }}
                      >
                        Month
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "32vw",
                        height: "8vw",
                        backgroundColor: "#4096D2",
                        borderRadius: "5vw",
                        fontFamily: "PT Sans",
                        fontSize: fs.button,
                        lineHeight: lineHeight(fs.button),
                        fontWeight: "bold",
                        letterSpacing: 0,
                        color: "white",
                      }}
                    >
                      {typeof monthEarnings === "number" ? monthEarnings.toString() : "---"} CA$H
                    </div>
                  </div>
                  <div
                    style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}
                  >
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        paddingRight: "4vw",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          textAlign: "end",
                          fontFamily: "PT Sans",
                          fontSize: fs.subtext,
                          lineHeight: lineHeight(fs.subtext),
                        }}
                      >
                        Week
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "32vw",
                        height: "8vw",
                        backgroundColor: "#4096D2",
                        borderRadius: "5vw",
                        fontFamily: "PT Sans",
                        fontSize: fs.button,
                        lineHeight: lineHeight(fs.button),
                        fontWeight: "bold",
                        letterSpacing: 0,
                        color: "white",
                      }}
                    >
                      {typeof weekEarnings === "number" ? weekEarnings.toString() : "---"} CA$H
                    </div>
                  </div>
                  <div
                    style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}
                  >
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        paddingRight: "4vw",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          textAlign: "end",
                          fontFamily: "PT Sans",
                          fontSize: fs.subtext,
                          lineHeight: lineHeight(fs.subtext),
                        }}
                      >
                        Today
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "32vw",
                        height: "8vw",
                        backgroundColor: "#4096D2",
                        borderRadius: "5vw",
                        fontFamily: "PT Sans",
                        fontSize: fs.button,
                        lineHeight: lineHeight(fs.button),
                        fontWeight: "bold",
                        letterSpacing: 0,
                        color: "white",
                      }}
                    >
                      {typeof dayEarnings === "number" ? dayEarnings.toString() : "---"} CA$H
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    height: "10vh",
                    fontFamily: "PT Sans",
                    fontWeight: "bold",
                    fontSize: fs.h2,
                    lineHeight: lineHeight(fs.h2),
                    letterSpacing: 0,
                    color: "#282560",
                    opacity: 1,
                  }}
                >
                  Current Balance:
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "5vh",
                  }}
                >
                  <div
                    style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "32vw",
                        height: "8vw",
                        backgroundColor: "#4096D2",
                        borderRadius: "5vw",
                        fontFamily: "PT Sans",
                        fontSize: fs.button,
                        lineHeight: lineHeight(fs.button),
                        fontWeight: "bold",
                        letterSpacing: 0,
                        color: "white",
                      }}
                    >
                      {balance ? balance.toString() : "---"} CA$H
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                width: "92%",
                height: 0,
                border: "1.5px solid #E5E5E5",
                borderRadius: "10px",
                margin: "1.5vh 4% 1.5vh 4%",
              }}
            ></div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                padding: "7vw",
                background: "#D5EFFC 0% 0% no-repeat padding-box",
                boxShadow: "0px 3px 6px #00000029",
                borderRadius: 30,
                opacity: 1,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  fontFamily: "PT Sans",
                  fontWeight: "bold",
                  fontSize: fs.h2,
                  lineHeight: lineHeight(fs.h2),
                  letterSpacing: 0,
                  color: "#282560",
                  opacity: 1,
                }}
              >
                Profile Completeness:
              </div>
              <p
                style={{
                  fontFamily: "PT Sans",
                  fontSize: fs.p1,
                  lineHeight: lineHeight(fs.p1),
                  letterSpacing: 0,
                  color: "#282560",
                  opacity: 1,
                  marginBottom: 15,
                }}
              >
                (Complete profiles will have earned 700 CA$H!)
              </p>

              {/* PROGRESS BAR and CRITERIA FOR COMPLETION */}
              <div
                style={{
                  width: "99%",
                  height: "4vh",
                  border: "3px solid #25AE88",
                  backgroundColor: twitterId && watchedAd && sharedAd ? "#25AE88" : "white",
                  opacity: 1,
                  marginBottom: "3vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    background: "#25AE88 0% 0% no-repeat padding-box",
                    opacity: 1,
                    position: "relative",
                    minHeight: "5vh",
                    top: "-0.1vh",
                    left: "-0.1vh",
                    width:
                      String(
                        40 + (twitterId ? 20 : 0) + (watchedAd ? 20 : 0) + (sharedAd ? 24 : 0)
                      ) + "%",
                  }}
                ></div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  marginBottom: 10,
                }}
              >
                <div
                  style={{
                    width: "6vw",
                    height: "6vw",
                    background: `transparent url(${checkedTickImg}) center/contain no-repeat padding-box`,
                    opacity: 1,
                    marginRight: "12vw",
                  }}
                ></div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "PT Sans",
                    fontSize: fs.p1,
                    lineHeight: lineHeight(fs.p1),
                    letterSpacing: 0,
                    color: "#282560",
                    opacity: 1,
                  }}
                >
                  Register for an Account
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  marginBottom: 10,
                }}
              >
                <div
                  style={{
                    width: "6vw",
                    height: "6vw",
                    background: `transparent url(${checkedTickImg}) center/contain no-repeat padding-box`,
                    opacity: 1,
                    marginRight: "12vw",
                  }}
                ></div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "PT Sans",
                    fontSize: fs.p1,
                    lineHeight: lineHeight(fs.p1),
                    letterSpacing: 0,
                    color: "#282560",
                    opacity: 1,
                  }}
                >
                  Fill in all details under Smart Profile
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  marginBottom: 10,
                }}
              >
                <div
                  style={{
                    width: "6vw",
                    height: "6vw",
                    background: `transparent url(${
                      twitterId ? checkedTickImg : uncheckedTickImg
                    }) center/contain no-repeat padding-box`,
                    opacity: 1,
                    marginRight: "12vw",
                  }}
                ></div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "PT Sans",
                    fontSize: fs.p1,
                    lineHeight: lineHeight(fs.p1),
                    letterSpacing: 0,
                    color: "#282560",
                    opacity: 1,
                  }}
                >
                  Link social accounts under Smart Profile
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  marginBottom: 10,
                }}
              >
                <div
                  style={{
                    width: "6vw",
                    height: "6vw",
                    background: `transparent url(${
                      watchedAd ? checkedTickImg : uncheckedTickImg
                    }) center/contain no-repeat padding-box`,
                    opacity: 1,
                    marginRight: "12vw",
                  }}
                ></div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "PT Sans",
                    fontSize: fs.p1,
                    lineHeight: lineHeight(fs.p1),
                    letterSpacing: 0,
                    color: "#282560",
                    opacity: 1,
                  }}
                >
                  Watch first ad video
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  marginBottom: 10,
                }}
              >
                <div
                  style={{
                    width: "6vw",
                    height: "6vw",
                    background: `transparent url(${
                      sharedAd ? checkedTickImg : uncheckedTickImg
                    }) center/contain no-repeat padding-box`,
                    opacity: 1,
                    marginRight: "12vw",
                  }}
                ></div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "PT Sans",
                    fontSize: fs.p1,
                    lineHeight: lineHeight(fs.p1),
                    letterSpacing: 0,
                    color: "#282560",
                    opacity: 1,
                  }}
                >
                  Share first ad video
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <>
        {/* main dashboard content */}
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "flex-start",
            marginLeft: 45,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              padding: "15px 80px 15px 30px",
              marginBottom: 15,
              background: "#D5EFFC 0% 0% no-repeat padding-box",
              boxShadow: "0px 3px 6px #00000029",
              border: "3px solid #D5EFFC",
              borderRadius: 39,
              opacity: 1,
            }}
          >
            <p
              style={{
                font: "Bold 25px/34px Open Sans",
                letterSpacing: 0,
                color: "#282560",
                opacity: 1,
              }}
            >
              Earnings History:
            </p>
            <p
              style={{
                font: "Regular 17px/23px Open Sans",
                letterSpacing: 0,
                color: "#282560",
                opacity: 1,
                marginBottom: 15,
              }}
            >
              (CA$H Earned)
            </p>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginRight: 10,
                }}
              >
                <div
                  style={{
                    background: "#4096D2 0% 0% no-repeat padding-box",
                    borderRadius: 50,
                    opacity: 1,
                    padding: 30,
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Open Sans",
                      fontSize: 25,
                      fontWeight: 700,
                      letterSpacing: 0,
                      color: "white",
                      opacity: 1,
                      textAlign: "center",
                      minWidth: 141,
                    }}
                  >
                    {typeof dayEarnings === "number" ? dayEarnings.toString() : "---"} CA$H
                  </p>
                </div>
                <p
                  style={{
                    font: "Regular 17px/22px PT Sans",
                    letterSpacing: 0,
                    color: "#282560",
                    opacity: 1,
                  }}
                >
                  Today
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginRight: 10,
                }}
              >
                <div
                  style={{
                    background: "#4096D2 0% 0% no-repeat padding-box",
                    borderRadius: 50,
                    opacity: 1,
                    padding: 30,
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Open Sans",
                      fontSize: 25,
                      fontWeight: 700,
                      letterSpacing: 0,
                      color: "white",
                      opacity: 1,
                      textAlign: "center",
                      minWidth: 141,
                    }}
                  >
                    {typeof weekEarnings === "number" ? weekEarnings.toString() : "---"} CA$H
                  </p>
                </div>
                <p
                  style={{
                    font: "Regular 17px/22px PT Sans",
                    letterSpacing: 0,
                    color: "#282560",
                    opacity: 1,
                  }}
                >
                  Week
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginRight: 10,
                }}
              >
                <div
                  style={{
                    background: "#4096D2 0% 0% no-repeat padding-box",
                    borderRadius: 50,
                    opacity: 1,
                    padding: 30,
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Open Sans",
                      fontSize: 25,
                      fontWeight: 700,
                      letterSpacing: 0,
                      color: "white",
                      opacity: 1,
                      textAlign: "center",
                      minWidth: 141,
                    }}
                  >
                    {typeof monthEarnings === "number" ? monthEarnings.toString() : "---"} CA$H
                  </p>
                </div>
                <p
                  style={{
                    font: "Regular 17px/22px PT Sans",
                    letterSpacing: 0,
                    color: "#282560",
                    opacity: 1,
                  }}
                >
                  Month
                </p>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              padding: "15px 80px 15px 30px",
              marginBottom: 15,
              background: "#D5EFFC 0% 0% no-repeat padding-box",
              boxShadow: "0px 3px 6px #00000029",
              border: "3px solid #D5EFFC",
              borderRadius: 39,
              opacity: 1,
            }}
          >
            <p
              style={{
                font: "Bold 25px/34px Open Sans",
                letterSpacing: 0,
                color: "#282560",
                opacity: 1,
              }}
            >
              Current Wallet Balance:
            </p>
            <p
              style={{
                font: "Regular 17px/23px Open Sans",
                letterSpacing: 0,
                color: "#282560",
                opacity: 1,
                marginBottom: 15,
              }}
            >
              (CA$H Earned)
            </p>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginRight: 10,
                }}
              >
                <div
                  style={{
                    background: "#4096D2 0% 0% no-repeat padding-box",
                    borderRadius: 50,
                    opacity: 1,
                    padding: 30,
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Open Sans",
                      fontSize: 25,
                      fontWeight: 700,
                      letterSpacing: 0,
                      color: "white",
                      opacity: 1,
                      textAlign: "center",
                      minWidth: 141,
                    }}
                  >
                    {balance ? balance.toString() : "---"} CA$H
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              padding: "15px 80px 15px 30px",
              background: "#D5EFFC 0% 0% no-repeat padding-box",
              boxShadow: "0px 3px 6px #00000029",
              border: "3px solid #D5EFFC",
              borderRadius: 39,
              opacity: 1,
            }}
          >
            <p
              style={{
                font: "Bold 25px/34px Open Sans",
                letterSpacing: 0,
                color: "#282560",
                opacity: 1,
              }}
            >
              Profile Completeness:
            </p>
            <p
              style={{
                font: "Regular 17px/23px Open Sans",
                letterSpacing: 0,
                color: "#282560",
                opacity: 1,
                marginBottom: 15,
              }}
            >
              (Complete profiles will have earned 700 CA$H!)
            </p>

            {/* PROGRESS BAR and CRITERIA FOR COMPLETION */}
            <div
              style={{
                width: 660,
                height: 42,
                border: "5px solid #25AE88",
                opacity: 1,
                marginBottom: 30,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  background: "#25AE88 0% 0% no-repeat padding-box",
                  opacity: 1,
                  height: 35,
                  width: 260 + (twitterId ? 130 : 0) + (watchedAd ? 130 : 0) + (sharedAd ? 130 : 0),
                }}
              ></div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                marginBottom: 10,
              }}
            >
              <div
                style={{
                  width: 30,
                  height: 30,
                  background: `transparent url(${checkedTickImg}) 0% 0% no-repeat padding-box`,
                  opacity: 1,
                  marginRight: 15,
                }}
              ></div>
              <p
                style={{
                  fontFamily: "PT Sans",
                  fontSize: 17,
                  lineHeight: "30px",
                  letterSpacing: 0,
                  color: "#282560",
                  opacity: 1,
                }}
              >
                Register for an Account
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                marginBottom: 10,
              }}
            >
              <div
                style={{
                  width: 30,
                  height: 30,
                  background: `transparent url(${checkedTickImg}) 0% 0% no-repeat padding-box`,
                  opacity: 1,
                  marginRight: 15,
                }}
              ></div>
              <p
                style={{
                  fontFamily: "PT Sans",
                  fontSize: 17,
                  lineHeight: "30px",
                  letterSpacing: 0,
                  color: "#282560",
                  opacity: 1,
                }}
              >
                Fill in all details under Smart Profile
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                marginBottom: 10,
              }}
            >
              <div
                style={{
                  width: 30,
                  height: 30,
                  background: `transparent url(${
                    twitterId ? checkedTickImg : uncheckedTickImg
                  }) 0% 0% no-repeat padding-box`,
                  opacity: 1,
                  marginRight: 15,
                }}
              ></div>
              <p
                style={{
                  fontFamily: "PT Sans",
                  fontSize: 17,
                  lineHeight: "30px",
                  letterSpacing: 0,
                  color: "#282560",
                  opacity: 1,
                }}
              >
                Link Social Media accounts under Smart Profile
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                marginBottom: 10,
              }}
            >
              <div
                style={{
                  width: 30,
                  height: 30,
                  background: `transparent url(${
                    watchedAd ? checkedTickImg : uncheckedTickImg
                  }) 0% 0% no-repeat padding-box`,
                  opacity: 1,
                  marginRight: 15,
                }}
              ></div>
              <p
                style={{
                  fontFamily: "PT Sans",
                  fontSize: 17,
                  lineHeight: "30px",
                  letterSpacing: 0,
                  color: "#282560",
                  opacity: 1,
                }}
              >
                Watch first ad video
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                marginBottom: 10,
              }}
            >
              <div
                style={{
                  width: 30,
                  height: 30,
                  background: `transparent url(${
                    sharedAd ? checkedTickImg : uncheckedTickImg
                  }) 0% 0% no-repeat padding-box`,
                  opacity: 1,
                  marginRight: 15,
                }}
              ></div>
              <p
                style={{
                  fontFamily: "PT Sans",
                  fontSize: 17,
                  lineHeight: "30px",
                  letterSpacing: 0,
                  color: "#282560",
                  opacity: 1,
                }}
              >
                Share first ad video
              </p>
            </div>
          </div>
        </div>

        {/* small nav buttons */}
        {/* <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-end', margin: 20 }}> */}
        <div style={{ position: "absolute", top: 0, right: 0, zIndex: 100 }}>
          <NavIcons user={this.props.user} imgSrc={this.state.profilePic} />
        </div>
      </>
    );
  }
}

export default Account;

import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

class Settings extends Component {

    handleReset = e => {
        e.preventDefault()

        this.props.beginLoading("Redirecting to Password Reset... This Process Will Temporarliy Log You From Your Account...")

        setTimeout(() => {
            this.props.endLoading("Redirecting to Password Reset... This Process Will Temporarliy Log You From Your Account...")
            debugger
            localStorage.removeItem('fabricUser')
            this.props.refreshUser()
            this.props.history.push('/reset')
        }, 5000)
    }

    render(){
        // const { dayEarnings, weekEarnings, monthEarnings, yearEarnings, balance } = this.state
        return(
            <div className="row">
                <div className="col-sm-2" />
                <div className="col-sm-8">
                    <div className="wallet" style={{marginTop: "50px"}}>
                        <div style={{marginBottom: "20px"}}>
                            <span>
                                <h4 style={{fontWeight: 700,fontSize: "20px", display: "inline-block"}}>
                                   Account Management</h4>
                                &nbsp;&nbsp;
                                {/* <span style={{fontSize: "16px"}}>Social Cash (SC$H)</span> */}
                            </span>
                            <div style={{ marginTop: "30px" }}>
                                <button type="button" class="btn btn-primary btn-lg" onClick={(e) => this.handleReset(e)}>Reset Password</button>
                            </div>
                            {/* <table style={{width: "100%", marginTop: "30px"}}>
                                <tbody>
                                <tr>
                                    <td>Password Reset</td>
                                    <td>Click Here to Initiate Password Reset</td>
                                </tr>
                                <tr>
                                    <td>Week</td>
                                    <td>{Number(weekEarnings).toLocaleString()} SC$H</td>
                                </tr>
                                <tr>
                                    <td>Year-to-Date</td>
                                    <td>{Number(yearEarnings).toLocaleString()} SC$H</td>
                                </tr>
                                </tbody>
                            </table> */}
                        </div>
                        
                        {/* <div style={{marginBottom: "20px"}}>
                            <span>
                                <h4 style={{fontWeight: 700,fontSize: "20px", display: "inline-block"}}>
                                Wallet Balance</h4>
                                &nbsp;&nbsp;
                                <span style={{fontSize: "16px"}}>Social Cash (SC$H)</span>
                            </span>
                            <table style={{width: "100%"}}>
                                <tbody>
                                <tr>
                                    <td>Current Balance</td>
                                    <td><strong>{Number(balance).toLocaleString()} SC$H</strong></td>
                                </tr>
                                </tbody>
                            </table>  
                        </div>

                        <div className="redeem">
                            <div className="row">
                                <div className="col-sm-3">
                                    <h3 style={{fontWeight: 700}}>Redeem</h3>
                                </div>
                                <div className="col-sm-4" style={{lineHeight: "60px", fontSize: "15px"}}>
                                    Social Cash (SC$H)
                                </div>
                                <div className="col-sm-5" style={{lineHeight: "60px", fontSize: "15px"}}>
                                    100 SC$H = 1.00 USD
                                </div>
                                
                            </div>
                            <div className="row">
                                <div className="col-sm-5" style={{paddingRight: "0px"}}>
                                    <div className="convert-input">
                                        <input type="number" value={this.state.socialCashAmt} onChange={(e) => this.setState({socialCashAmt: e.target.value})}>
                                        </input><span>SC$H</span>
                                    </div>
                                </div>
                                <div className="col-sm-1">
                                    <p style={{fontSize: "20px"}}>=</p>
                                </div>
                                <div className="col-sm-5" style={{paddingRight: "0px"}}>
                                    <div className="convert-input">
                                        <input type="number" value={this.state.socialCashAmt / 100} readOnly></input>
                                        <span>USD</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">      
                                <div className="col-sm-5">
                                   <div className="redeem-button">
                                        Redeem Social Cash (SC$H) to Debit Card
                                    </div> 
                                </div>
                                <div className="col-sm-1"/>
                                <div className="col-sm-5">
                                    <div className="redeem-button">
                                        Redeem Social Cash (SC$H) for Products/Services
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="col-sm-2" />
            </div>
        )
    }
}

export default withRouter(Settings)
import React, { useContext } from "react";
import PropTypes from "prop-types";
import { NavLink, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import UserContext from "../contexts/UserContext";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: 60,
    width: 225,
    marginBottom: 20,
    marginRight: 40
  },
  linkText: {
    paddingTop: 15,
    paddingLeft: 20
  },
  text: {
    color: theme.palette.text.primary,
    textAlign: "left",
    verticalAlign: "middle",
    height: 41
  },
  activeButton: {
    width: 264,
    height: 65,
    background: "#F4FBFF 0% 0% no-repeat padding-box",
    borderTopLeftRadius: 33,
    borderBottomLeftRadius: 33,
    opacity: 1
  }
}));

function NavBarItem({ text, linkTo }) {
  const classes = useStyles();
  const { handleMenuClick } = useContext(UserContext);

  return (
    <div className={classes.container}>
      <NavLink
        exact
        strict
        to={linkTo}
        activeClassName={classes.activeButton}
        className={classes.linkText}
        onClick={handleMenuClick}
      >
        <Typography className={classes.text} variant="h5">
          {text}
        </Typography>
      </NavLink>
    </div>
  );
}

NavBarItem.propTypes = {
  text: PropTypes.string,
  linkTo: PropTypes.string
};

export default NavBarItem;

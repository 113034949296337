export default [{
    "_id": "category$root",
    "displayName": "root",
    "descriptiveName": "root",
    "children": ["category$fabric", "category$clothing", "category$restaurants",
    "category$household", "category$groceries", "category$electronics",
    "category$travel"]
},
{
    "_id": "category$fabric",
    "displayName": "Fabric",
    "descriptiveName": "fabric",
    "priceRanges": "Under $10, $10 - $50, $51 - $100, $101 - $300, Over $300"
},
{
    "_id": "category$clothing",
    "displayName": "Clothing",
    "descriptiveName": "clothing",
    "children": ["category$clothing.mens", "category$clothing.womens"],
    "priceRanges": "Under $10, $10 - $50, $51 - $100, $101 - $300, Over $300"
},
{
    "_id": "category$restaurants",
    "displayName": "Restaurants",
    "descriptiveName": "restaurants",
    "children": [{"_id": "category",
                "displayName": "Fast Food",
                "descriptiveName": "restaurants.fastFood",
                "priceRanges": "Under $10, $10 - $50, $51 - $100, $101 - $300, Over $300"}],
    "priceRanges": "Under $10, $10 - $50, $51 - $100, $101 - $300, Over $300"
},
{
    "_id": "category$household",
    "displayName": "Household",
    "descriptiveName": "household",
    "children": [{"_id": "category",
                "displayName": "Kitchen",
                "descriptiveName": "household.kitchen",
                "priceRanges": "Under $10, $10 - $50, $51 - $100, $101 - $300, Over $300"}],
    "priceRanges": "Under $10, $10 - $50, $51 - $100, $101 - $300, Over $300"
},
{
    "_id": "category$groceries",
    "displayName": "Groceries",
    "descriptiveName": "groceries",
    "children": [{"_id": "category",
                "displayName": "Pantry",
                "descriptiveName": "groceries.pantry",
                "priceRanges": "Under $10, $10 - $50, $51 - $100, $101 - $300, Over $300"}],
    "priceRanges": "Under $10, $10 - $50, $51 - $100, $101 - $300, Over $300"
},
{
    "_id": "category$electronics",
    "displayName": "Electronics",
    "descriptiveName": "electronics",
    "children": [{"_id": "category",
                "displayName": "Camera",
                "descriptiveName": "electronics.camera",
                "priceRanges": "Under $10, $10 - $50, $51 - $100, $101 - $300, Over $300"}],
    "priceRanges": "Under $10, $10 - $50, $51 - $100, $101 - $300, Over $300"
},
{
    "_id": "category$travel",
    "displayName": "Travel",
    "descriptiveName": "travel",
    "children": [{"_id": "category",
                "displayName": "United States",
                "descriptiveName": "travel.unitedStates",
                "priceRanges": "Under $500, $501 - $1000, $1001 - $1500, $1501 - $2000, Over $2000"}],
    "priceRanges": "Under $500, $501 - $1000, $1001 - $1500, $1501 - $2000, Over $2000"
},
{
    "_id": "category$clothing.mens",
    "displayName": "Mens",
    "descriptiveName": "clothing.mens",
    "children": ["category$clothing.mens.formal", "category$clothing.mens.casual", "category$clothing.mens.business"],
    "priceRanges": "Under $10, $10 - $50, $51 - $100, $101 - $300, Over $300"
},
{
    "_id": "category$clothing.womens",
    "displayName": "Womens",
    "descriptiveName": "clothing.womens",
    "children": ["category$clothing.womens.formal", "category$clothing.womens.casual", "category$clothing.womens.business"],
    "priceRanges": "Under $10, $10 - $50, $51 - $100, $101 - $300, Over $300"
},
{
    "_id": "category$clothing.mens.formal",
    "displayName": "Formal",
    "descriptiveName": "clothing.mens.formal",
    "priceRanges": "Under $50, $51 - $100, $101 - $300, $300-$500, Over $500"
},
{
    "_id": "category$clothing.mens.casual",
    "displayName": "Casual",
    "descriptiveName": "clothing.mens.casual",
    "priceRanges": "Under $10, $10 - $50, $51 - $100, $101 - $300, Over $300"
},
{
    "_id": "category$clothing.mens.business",
    "displayName": "Business",
    "descriptiveName": "clothing.mens.business",
    "priceRanges": "Under $50, $51 - $100, $101 - $300, $300-$500, Over $500",
    "children": ["category$clothing.mens.business.shirts", "category$clothing.mens.business.slacks",
    "category$clothing.mens.business.suits"]
},
{
    "_id": "category$clothing.mens.business.shirts",
    "displayName": "Shirts",
    "descriptiveName": "clothing.mens.business.shirts",
    "priceRanges": "Under $50, $51 - $100, $101 - $300, $300-$500, Over $500"
}, 
{
    "_id": "category$clothing.mens.business.slacks",
    "displayName": "Slacks",
    "descriptiveName": "clothing.mens.business.slacks",
    "priceRanges": "Under $50, $51 - $100, $101 - $300, $300-$500, Over $500"
},
{
    "_id": "category$clothing.mens.business.suits",
    "displayName": "Suits",
    "descriptiveName": "clothing.mens.business.suits",
    "priceRanges": "Under $500, $501 - $1000, $1001 - $1500, $1500 - $2500, Over $2500",
    "children": ["category$clothing.mens.business.suits.discount", "category$clothing.mens.business.suits.designer"]
},
{
    "_id": "category$clothing.mens.business.suits.discount",
    "displayName": "Discount",
    "descriptiveName": "clothing.mens.business.suits.discount",
    "priceRanges": "Under $500, $501 - $1000, $1001 - $1500, $1500 - $2500, Over $2500"
},
{
    "_id": "category$clothing.mens.business.suits.designer",
    "displayName": "Designer",
    "descriptiveName": "clothing.mens.business.suits.designer",
    "priceRanges": "Under $1500, $1501 - $2500, $2501 - $3500, $3501 - $4500, Over $4500"
},
{
    "_id": "category$clothing.womens.formal",
    "displayName": "Formal",
    "descriptiveName": "clothing.womens.formal",
    "priceRanges": "Under $50, $51 - $100, $101 - $300, $300-$500, Over $500"
},
{
    "_id": "category$clothing.womens.casual",
    "displayName": "Casual",
    "descriptiveName": "clothing.womens.casual",
    "priceRanges": "Under $10, $10 - $50, $51 - $100, $101 - $300, Over $300"
},
{
    "_id": "category$clothing.womens.business",
    "displayName": "Business",
    "descriptiveName": "clothing.womens.business",
    "priceRanges": "Under $50, $51 - $100, $101 - $300, $300-$500, Over $500",
    "children": ["category$clothing.womens.business.shirts", "category$clothing.womens.business.slacks",
    "category$clothing.womens.business.suits"]
},
{
    "_id": "category$clothing.womens.business.shirts",
    "displayName": "Shirts",
    "descriptiveName": "clothing.womens.business.shirts",
    "priceRanges": "Under $50, $51 - $100, $101 - $300, $300-$500, Over $500"
}, 
{
    "_id": "category$clothing.womens.business.slacks",
    "displayName": "Slacks",
    "descriptiveName": "clothing.womens.business.slacks",
    "priceRanges": "Under $50, $51 - $100, $101 - $300, $300-$500, Over $500"
},
{
    "_id": "category$clothing.womens.business.suits",
    "displayName": "Suits",
    "descriptiveName": "clothing.womens.business.suits",
    "priceRanges": "Under $500, $501 - $1000, $1001 - $1500, $1500 - $2500, Over $2500",
    "children": ["category$clothing.womens.business.suits.discount", "category$clothing.womens.business.suits.designer"]
},
{
    "_id": "category$clothing.womens.business.suits.discount",
    "displayName": "Discount",
    "descriptiveName": "clothing.womens.business.suits.discount",
    "priceRanges": "Under $500, $501 - $1000, $1001 - $1500, $1500 - $2500, Over $2500"
},
{
    "_id": "category$clothing.womens.business.suits.designer",
    "displayName": "Designer",
    "descriptiveName": "clothing.womens.business.suits.designer",
    "priceRanges": "Under $1500, $1501 - $2500, $2501 - $3500, $3501 - $4500, Over $4500"
}]
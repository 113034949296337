// export default [{
//     "_id": "ad$coca",
//     "id": 6,
//     "finished": true, 
//     "name": "Coca Cola Classic Ad",
//     "video": "initialData/ads/a_coke_is_a_coke.mp4", 
//     "coverImage": "initialData/ads/cocaColaAdCoverImage.png",
//     "thumbnail": "initialData/ads/cocaColaAdThumbnail.png",
//     "question": "What key word describes the overall message in the Coca-Cola video?",
//     "answerChoices": ["Bears", "Unity", "Together"],
//     "correctAnswer": "Together",
//     "videoPoints": 10,
//     "questionPoints": 30,
//     "postPoints": 30,
//     "company": {
//         "_id":  "company$coke",
//         "name": "Coca Cola",
//         "phoneNumber": "5239027890",
//         "address": "1 Coca Cola Lane, Soda City, NY",
//         "tags": ["beverage", "food"],
//         "logo": "initialData/companies/cocaColaLogo.png"
//     },
//     "tagline": "Together, we can make a world of difference.",
//     "cashtag": "$TogetherIsBeautiful",
//     "categories": [["category/descriptiveName", "groceries.pantry"]],
//     "budget": 1000,
//     "budgetRemaining": 1000
// },
// {
//     "_id": "ad$tide",
//     "id": 5,
//     "finished": true, 
//     "name": "Tide Alien Ad",
//     "video": "initialData/ads/a_coke_is_a_coke.mp4", 
//     "coverImage": "initialData/ads/tideCoverImage.jpg",
//     "thumbnail": "initialData/ads/tideAdThumbnail.png",
//     "question": "What key word describes the overall message in the Tide video?",
//     "answerChoices": ["Bears", "Unity", "Together"],
//     "correctAnswer": "Together",
//     "videoPoints": 10,
//     "questionPoints": 30,
//     "postPoints": 30,
//     "company": {
//         "_id":  "company",
//         "name": "Tide",
//         "phoneNumber": "7628950980",
//         "address": "1 Tide Drive, Laundry City, AK",
//         "tags": ["home", "laundry"],
//         "logo": "initialData/companies/tideLogo.png"
//     },
//     "tagline": "Tide!",
//     "cashtag": "$WashYerClothes",
//     "categories": [["category/descriptiveName", "household"]],
//     "budget": 1000,
//     "budgetRemaining": 1000
// },
// {
//     "_id": "ad$other",
//     "id": 2,
//     "finished": true, 
//     "name": "Other",
//     "video": "initialData/ads/a_coke_is_a_coke.mp4", 
//     "coverImage": "initialData/ads/otherThumbnail.png",
//     "thumbnail": "initialData/ads/otherThumbnail.png",
//     "question": "What key word describes the overall message in this video?",
//     "answerChoices": ["Bears", "Unity", "Together"],
//     "correctAnswer": "Together",
//     "videoPoints": 10,
//     "questionPoints": 30,
//     "postPoints": 30,
//     "company": {
//         "_id":  "company",
//         "name": "Other",
//         "phoneNumber": "7801232922",
//         "address": "123 Other Rd, Other City, USA",
//         "tags": ["other"],
//         "logo": "initialData/ads/otherThumbnail.png"
//     },
//     "tagline": "Other!",
//     "cashtag": "$Other",
//     "categories": [ ["category/descriptiveName", "clothing.mens"],
//                     ["category/descriptiveName",  "clothing.womens"]],
//     "budget": 1000,
//     "budgetRemaining": 1000
// },
// {
//     "_id": "ad$camera",
//     "id": 4,
//     "finished": true, 
//     "name": "Camera thing",
//     "video": "initialData/ads/a_coke_is_a_coke.mp4", 
//     "coverImage": "initialData/ads/otherThumbnail2.png",
//     "thumbnail": "initialData/ads/otherThumbnail2.png",
//     "question": "What key word describes the overall message in this video?",
//     "answerChoices": ["Bears", "Unity", "Together"],
//     "correctAnswer": "Together",
//     "videoPoints": 10,
//     "questionPoints": 30,
//     "postPoints": 30,
//     "company": {
//         "_id":  "company",
//         "name": "Cameras",
//         "phoneNumber": "2801232922",
//         "address": "124 Camera Rd, Camera  City, USA",
//         "tags": ["camera"],
//         "logo": "initialData/ads/otherThumbnail2.png"
//     },
//     "tagline": "Other!",
//     "cashtag": "$Other",
//     "categories": [["category/descriptiveName", "electronics.camera"]],
//     "budget": 1000,
//     "budgetRemaining": 1000
// },
// {
//     "_id": "ad$other2",
//     "id": 3,
//     "finished": true, 
//     "name": "Other 2",
//     "video": "initialData/ads/a_coke_is_a_coke.mp4", 
//     "coverImage": "initialData/ads/otherThumbnail3.png",
//     "thumbnail": "initialData/ads/otherThumbnail3.png",
//     "question": "What key word describes the overall message in this video?",
//     "answerChoices": ["Bears", "Unity", "Together"],
//     "correctAnswer": "Together",
//     "videoPoints": 10,
//     "questionPoints": 30,
//     "postPoints": 30,
//     "company": {
//         "_id":  "company",
//         "name": "Other 2",
//         "phoneNumber": "7101232922",
//         "address": "125 Other Rd, Other City, USA",
//         "tags": ["other"],
//         "logo": "initialData/ads/otherThumbnail3.png"
//     },
//     "tagline": "Other!",
//     "cashtag": "$Other",
//     "categories": [["category/descriptiveName", "household.kitchen"]],
//     "budget": 1000,
//     "budgetRemaining": 1000
// },
// {
//     "_id": "ad$other3",
//     "id": 1,
//     "finished": true, 
//     "name": "Other 3",
//     "video": "initialData/ads/a_coke_is_a_coke.mp4", 
//     "coverImage": "initialData/ads/otherThumbnail4.png",
//     "thumbnail": "initialData/ads/otherThumbnail4.png",
//     "question": "What key word describes the overall message in this video?",
//     "answerChoices": ["Bears", "Unity", "Together"],
//     "correctAnswer": "Together",
//     "videoPoints": 10,
//     "questionPoints": 30,
//     "postPoints": 30,
//     "company": {
//         "_id":  "company",
//         "name": "Other 3",
//         "phoneNumber": "7101232923",
//         "address": "126 Other Rd, Other City, USA",
//         "tags": ["other"],
//         "logo": "initialData/ads/otherThumbnail3.png"
//     },
//     "tagline": "Other!",
//     "cashtag": "$Other",
//     "categories": [["category/descriptiveName", "travel"]],
//     "budget": 1000,
//     "budgetRemaining": 1000
// },
// {
//     "_id": "ad$brioni",
//     "id": 7,
//     "finished": true, 
//     "name": "Brioni",
//     "video": "initialData/ads/brioni.mp4", 
//     "coverImage": "initialData/ads/brioni.mp4",
//     "thumbnail": "initialData/ads/brioniAdThumbnail.jpg",
//     "question": "The commercial featured?",
//     "answerChoices": ["Ocean", "Space", "Mountains"],
//     "correctAnswer": "Mountains",
//     "videoPoints": 10,
//     "questionPoints": 30,
//     "postPoints": 30,
//     "company": {
//         "_id":  "company",
//         "name": "Brioni",
//         "phoneNumber": "7501232923",
//         "address": "126 Other Rd, Brioni, Italy",
//         "tags": ["clothing"],
//         "logo": "initialData/ads/brioniAdThumbnail.jpg"
//     },
//     "tagline": "Clothes!",
//     "cashtag": "$NiceClothes!",
//     "categories": [["category/descriptiveName", "clothing.mens.business"]],
//     "budget": 1000,
//     "budgetRemaining": 1000
// },
// {
//     "_id": "ad$armani",
//     "id": 8,
//     "finished": true,  
//     "name": "Armani",
//     "video": "initialData/ads/armani.mp4", 
//     "coverImage": "initialData/ads/armaniCoverPhoto.jpg",
//     "thumbnail": "initialData/ads/armaniAdThumbnail.jpg",
//     "question": "What color was the shirt?",
//     "answerChoices": ["Blue", "Green", "White"],
//     "correctAnswer": "White",
//     "videoPoints": 10,
//     "questionPoints": 30,
//     "postPoints": 30,
//     "company": {
//         "_id":  "company",
//         "name": "Armani",
//         "phoneNumber": "9501232923",
//         "address": "126 Other Rd, Armani, Italy",
//         "tags": ["clothing"],
//         "logo": "initialData/ads/armaniCoverPhoto.jpg"
//     },
//     "tagline": "LoveArmani",
//     "cashtag": "$LoveArmani",
//     "categories": [["category/descriptiveName", "clothing.mens.casual"]],
//     "budget": 1000,
//     "budgetRemaining": 1000
// },
// {
//     "_id": "ad$tomFord",
//     "id": 9,
//     "finished": true,  
//     "name": "Tom Ford",
//     "video": "initialData/ads/tom_ford.mp4", 
//     "coverImage": "initialData/ads/tomFordAdThumbnail.jpg",
//     "thumbnail": "initialData/ads/tomFordAdThumbnail.jpg",
//     "question": "What was not part of the outfit?",
//     "answerChoices": ["Medical boot", "Shirt", "Hat"],
//     "correctAnswer": "Hat",
//     "videoPoints": 10,
//     "questionPoints": 30,
//     "postPoints": 30,
//     "company": {
//         "_id":  "company",
//         "name": "Tom Ford",
//         "phoneNumber": "1501232923",
//         "address": "126 Other Rd, Tom Ford, Italy",
//         "tags": ["clothing"],
//         "logo": "initialData/ads/tomFordAdThumbnail.jpg"
//     },
//     "tagline": "TomFord!",
//     "cashtag": "$TomFord",
//     "categories": [["category/descriptiveName", "clothing.mens.business"]],
//     "budget": 1000,
//     "budgetRemaining": 1000
// },
// {
//     "_id": "ad$bmw",
//     "id": 10,
//     "finished": true,  
//     "name": "BMW",
//     "video": "initialData/ads/bmw.mp4", 
//     "coverImage": "initialData/ads/bmwThumbnail.jpg",
//     "thumbnail": "initialData/ads/bmwThumbnail.jpg",
//     "question": "What model BMW was in the video?",
//     "answerChoices": ["E30", "X5", "3 Series"],
//     "correctAnswer": "X5",
//     "videoPoints": 10,
//     "questionPoints": 30,
//     "postPoints": 30,
//     "bonusQuestion": "Are you currently in the market for a new vehicle?",
//     "bonusQuestionAnswerChoices": ["Yes", "No", "I prefer not to say"],
//     "company": {
//         "_id":  "company",
//         "name": "BMW",
//         "phoneNumber": "150122923",
//         "address": "126 Other Rd, Bavaria, Germany",
//         "tags": ["cars"],
//         "logo": "initialData/ads/bmwThumbnail.jpg"
//     },
//     "tagline": "BMW",
//     "cashtag": "$BMW",
//     "categories": [["category/descriptiveName", "travel"]],
//     "budget": 1000,
//     "budgetRemaining": 1000
// },
// {
//     "_id": "ad$dietCoke",
//     "id": 11,
//     "finished": true,  
//     "name": "DietCoke",
//     "video": "initialData/ads/diet_coke.mp4", 
//     "coverImage": "initialData/ads/diet_cokeThumbnail.jpg",
//     "thumbnail": "initialData/ads/diet_cokeThumbnail.jpg",
//     "question": " How many kittens were in the video?",
//     "answerChoices": ["1", "3", "Too many to count"],
//     "correctAnswer": "Too many to count",
//     "videoPoints": 10,
//     "questionPoints": 30,
//     "postPoints": 30,
//     "company": "company$coke",
//     "tagline": "SoFresh",
//     "cashtag": "$SoFresh",
//     "categories": [["category/descriptiveName", "groceries.pantry"]],
//     "budget": 1000,
//     "budgetRemaining": 1000
// }
// // {
// //     "_id": ["user/handle", "vanessa"],
// //     "shoppingInterests": [["category/descriptiveName", "clothing.womens.business.suits.designer"],
// //     ["category/descriptiveName", "groceries.pantry"],
// //     ["category/descriptiveName", "electronics"]]
// // }
// ]

export default [{
    "_id": "ad$fabric",
    "id": 1,
    "finished": true,
    "name": "Fabric Ad",
    "video": "initialData/ads/fabric.mp4",
    "coverImage": "initialData/ads/fabricAdCoverImage.png",
    "thumbnail": "initialData/ads/fabricAdThumbnail.png",
    "questions": [{
        "_id": "question$fabric1",
        "type": "Scale",
        "priority": 3,
        "label": "How relevant is this ad to your consumer experience?",
        "numAnswers": 5,
        "answerChoices": ["Irrelevant", "Very Relevant"],
        "answerPoints": 50
    },
    {
        "_id": "question$fabric2",
        "type": "Scale",
        "priority": 5,
        "label": "How interested are you in continuing to participate with this brand?",
        "numAnswers": 5,
        "answerChoices": ["Not Interested", "Very Interested"],
        "answerPoints": 50
    }],
    "videoPoints": 200,
    "postPoints": 100,
    "company": {
        "_id": "company$fabric",
        "name": "Fabric",
        "phoneNumber": "3104016070",
        "address": "2032 Broadway, Santa Monica, California, USA",
        "tags": ["data", "advertising", "electronics", "other"],
        "logo": "initialData/companies/fabricLogo.png"
    },
    "tagline": "Collectively, we can create an advertising landscape that benefits the consumer. Our data. Our terms.",
    "cashtag": "$OwnYourData",
    "categories": [["category/descriptiveName", "fabric"]],
    "budget": 100000,
    "budgetRemaining": 100000
},
{
    "_id": "ad$BMW",
    "id": 2,
    "finished": true,
    "name": "BMW Ad",
    "video": "initialData/ads/BMWX72020.mp4",
    "coverImage": "initialData/ads/bmwcover.jpg",
    "thumbnail": "initialData/ads/bmwthumb.png",
    "questions": [{
        "_id": "question$bmw1",
        "type": "Test",
        "priority": 1,
        "label": "What is the Brand / Model featured?",
        "numAnswers": 3,
        "answerChoices": ["BMW 3", "BMW 5", "BMW X7"],
        "correctAnswer": "BMW X7",
        "answerPoints": 50
    },
    {
        "_id": "question$bmw2",
        "type": "YesNo",
        "priority": 5,
        "label": "Are you in the market for a new vehicle?",
        "numAnswers": 2,
        "answerChoices": ["No", "Yes"],
        "answerPoints": 100
    },
    {
        "_id": "question$bmw3",
        "type": "YesNo",
        "priority": 10,
        "label": "Would you like to be contacted by your local dealership to schedule a test drive?",
        "numAnswers": 2,
        "answerChoices": ["No", "Yes"],
        "answerPoints": 250
    }],
    "videoPoints": 200,
    "postPoints": 100,
    "company": {
        "_id": "company",
        "name": "BMW",
        "phoneNumber": "150122923",
        "address": "126 Other Rd, Bavaria, Germany",
        "tags": ["cars"],
        "logo": "initialData/ads/bmwthumb.png"
    },
    "tagline": "Make every day legendary.",
    "cashtag": "$BMWX72020",
    "categories": [["category/descriptiveName", "fabric"]],
    "budget": 100000,
    "budgetRemaining": 100000
},
{
    "_id": "ad$Coke",
    "id": 3,
    "finished": true,
    "name": "Coke Ad",
    "video": "initialData/ads/CocaColaTeamSpirit.mp4",
    "coverImage": "initialData/ads/cocaColaAdCoverImage.png",
    "thumbnail": "initialData/ads/cocaColaAdThumbnail.png",
    "questions": [{
        "_id": "question$coke1",
        "type": "Test",
        "priority": 1,
        "label": "What brand is featured in the ad?",
        "numAnswers": 3,
        "answerChoices": ["Coca-Cola", "Pepsi", "Whole Foods"],
        "correctAnswer": "Coca-Cola",
        "answerPoints": 50
    },
    {
        "_id": "question$coke2",
        "type": "Test",
        "priority": 2,
        "label": "What is the theme of the 2019 campaign?",
        "numAnswers": 3,
        "answerChoices": ["Back to School", "Friends", "Team Spirit"],
        "correctAnswer": "Team Spirit",
        "answerPoints": 100
    },
    {
        "_id": "question$coke3",
        "type": "Action",
        "priority": 10,
        "label": "Scan a Coke to Win Promotion!",
        "numAnswers": 1,
        "answerChoices": ["Scan Now!"],
        "actionURI": "https://us.coca-cola.com/?gclsrc=aw.ds&gclid=EAIaIQobChMIgaPV-ujz5AIVlqDsCh140g9vEAAYASAAEgKNvvD_BwE",
        "answerPoints": 250
    }],
    "videoPoints": 200,
    "postPoints": 100,
    "company": {
        "_id": "company$coke",
        "name": "Coca Cola",
        "phoneNumber": "5239027890",
        "address": "1 Coca Cola Lane, Soda City, NY",
        "tags": ["beverage", "food"],
        "logo": "initialData/companies/cocaColaLogo.png"
    },
    "tagline": "Share some team spirit when you Share a Coke with your team on it this season.",
    "cashtag": "$TeamSpirit",
    "categories": [["category/descriptiveName", "fabric"]],
    "budget": 100000,
    "budgetRemaining": 100000
},
{
    "_id": "ad$tide",
    "id": 4,
    "finished": true,
    "name": "Tide ad",
    "video": "initialData/ads/TidePods.mp4",
    "coverImage": "initialData/ads/tideCoverImage.png",
    "thumbnail": "initialData/ads/tideAdThumbnail.png",
    "questions": [{
        "_id": "question$tide1",
        "type": "Test",
        "priority": 1,
        "label": "What is the name of the new cleaning innovation that saves time and mess?",
        "numAnswers": 3,
        "answerChoices": ["Speedy Clean", "Tide Pod", "Super Gel"],
        "correctAnswer": "Tide Pod",
        "answerPoints": 50
    },
    {
        "_id": "question$tide2",
        "type": "Test",
        "priority": 2,
        "label": "What is the upgrade?",
        "numAnswers": 3,
        "answerChoices": ["4-in-1 Technology", "Fast Wash", "Dry Scrub"],
        "correctAnswer": "4-in-1 Technology",
        "answerPoints": 100
    },
    {
        "_id": "question$tide3",
        "type": "Action",
        "priority": 10,
        "label": "Save on Tide Products with these promotions",
        "numAnswers": 1,
        "answerChoices": ["View Promotions"],
        "actionURI": "https://www.pgeveryday.com/brands/tide",
        "answerPoints": 0
    }],
    "videoPoints": 200,
    "questionPoints": 100,
    "postPoints": 100,
    "company": {
        "_id":  "company",
        "name": "Tide",
        "phoneNumber": "7628950980",
        "address": "1 Tide Drive, Laundry City, AK",
        "tags": ["home", "laundry"],
        "logo": "initialData/companies/tideLogo.png"
    },
    "tagline": "Technology for long lasting freshness",
    "cashtag": "$FoolProofClean",
    "categories": [["category/descriptiveName", "fabric"]],
    "budget": 100000,
    "budgetRemaining": 100000
}]
import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Container, Grid } from "@material-ui/core";
import LevelUpComp from "../LevelUp";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    background: theme.palette.primary.light,
    width: "100%"
  },
  grid: {
    marginTop: theme.spacing(2)
  },
  title: {
    color: theme.palette.text.primary
  }
}));

function LevelUp() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth={true}>
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            <LevelUpComp></LevelUpComp>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default LevelUp;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
// import checkedTickImg from "../assets/img/dashboard/002-tick-1.png";
// import uncheckedTickImg from "../assets/img/dashboard/001-tick.png";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    margin: '6 10'
  },
  title: {
    margin: theme.spacing(0, 1),
    color: theme.palette.text.primary,
    alignSelf: 'center'
  },
  checkIcon: {
    width: 30,
    height: 30,
    opacity: 1,
    marginRight: 15
  }
}));

function ProfileCompletenessItem({ checked, text }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {checked && (
        <CheckCircleIcon
          color="secondary"
          className={classes.checkIcon}
        ></CheckCircleIcon>
      )}
      {!checked && (
        <CheckCircleOutlineIcon
          className={classes.checkIcon}
        ></CheckCircleOutlineIcon>
      )}

      {/* <div
        className={classes.checkIcon}
        style={{
          background: `transparent url(${
            checked ? checkedTickImg : uncheckedTickImg
          })`
        }}
      ></div> */}

      <Typography variant="body1" className={classes.title}>
        {text}
      </Typography>
    </div>
  );
}

ProfileCompletenessItem.propTypes = {
  checked: PropTypes.bool,
  text: PropTypes.string
};

export default ProfileCompletenessItem;

import React, { useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  LinearProgress
} from "@material-ui/core";
import UserContext from "../contexts/UserContext";
import ProfileCompletenessItem from "./ProfileCompletenessItem";

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 20,
    height: 350
  },
  content: {},
  inner: {
    //height: 175
    //minWidth: 250
  },
  progress: {
    //margin: theme.spacing(0, 1),
    flexGrow: 1,
    height: 35
  },
  progressBorder: {
    border: "5px solid",
    borderColor: theme.palette.primary.dark,
    opacity: 1,
    marginBottom: 30,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  colorPrimary: {
    backgroundColor: "#FFFFFF"
  },
  barColorPrimary: {
    backgroundColor: theme.palette.primary.dark
  },
  subheader: {
    fontSize: theme.typography.pxToRem(12)
  }
}));

function ProfileCompleteness(props) {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const twitterId = user["user/twitterId"];
  const watchedAd =
    user["user/userAds"] &&
    user["user/userAds"][0] &&
    !!user["user/userAds"][0]["userAd/watched"];
  const sharedAd =
    user["user/userAds"] &&
    user["user/userAds"][0] &&
    !!user["user/userAds"][0]["userAd/sharedOnTwitter"];
  let progress =
    40 + (twitterId ? 20 : 0) + (watchedAd ? 20 : 0) + (sharedAd ? 20 : 0);

  return (
    <Card className={classes.root}>
      <CardHeader
        title="Profile Completeness"
        subheader="Complete your profile to earn 700 CA$H!"
        classes={{ subheader: classes.subheader }}
        titleTypographyProps={{ variant: 'h3' }}
        subheaderTypographyProps={{ variant: 'body1' }}
      />
      <CardContent className={classes.content}>
        <div className={classes.inner}>
          <div className={classes.progressBorder}>
            <LinearProgress
              className={classes.progress}
              classes={{
                colorPrimary: classes.colorPrimary,
                barColorPrimary: classes.barColorPrimary
              }}
              value={progress}
              variant="determinate"
            />
          </div>

          <ProfileCompletenessItem
            checked={true}
            text="Register for an account"
          ></ProfileCompletenessItem>

          <ProfileCompletenessItem
            checked={true}
            text="Fill in all details under Smart Profile"
          ></ProfileCompletenessItem>

          <ProfileCompletenessItem
            checked={twitterId ? true : false}
            text="Link social accounts under Smart Profile"
          ></ProfileCompletenessItem>

          <ProfileCompletenessItem
            checked={watchedAd ? true : false}
            text="Watch first ad video"
          ></ProfileCompletenessItem>

          <ProfileCompletenessItem
            checked={sharedAd ? true : false}
            text="Share first ad video"
          ></ProfileCompletenessItem>
        </div>
      </CardContent>
    </Card>
  );
}

ProfileCompleteness.propTypes = {};

export default ProfileCompleteness;

import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import { Card, CardHeader, CardContent, Box, Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import get from "lodash.get";
import UserContext from "../contexts/UserContext";
import { formatNumber as fN } from '../utils/functions'
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 20,
    height: 350,
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: 1
  },
  inner: {
    width: "100%",
    textAlign: "center",
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  title: {
    textAlign: "center",
    color: theme.palette.text.primary,
    marginTop: 20
  },
  buttonLabel: {
    textTransform: "none",
    color: "#FFFFFF"
  },
  button: {
    borderRadius: 20,
    width: 150,
    alignSelf: 'center'
  },
  box: {
    background: theme.palette.primary.main,
    height: 70,
    width: "50%",
    borderRadius: 10
  },
  boxContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 20
  },
  subheader: {
    fontSize: theme.typography.pxToRem(12)
  }
}));

function BalanceOverview() {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const walletBalance = get(user, ["user/wallet", "wallet/balance"]);
  const [socialCashAmt, setSocialCashAmt] = useState(walletBalance);

  return (
    <Card className={classes.root}>
      <CardHeader
        title="Balance Overview"
        subheader="(Current Available CA$H)"
        classes={{ subheader: classes.subheader }}
        titleTypographyProps={{ variant: 'h3' }}
        subheaderTypographyProps={{ variant: 'body1' }}
      />
      <CardContent className={classes.content}>
        <div className={classes.inner}>
          <div className={classes.boxContainer}>
            <Box className={classes.box}>
              <Typography variant="h6" className={classes.title}>
                {socialCashAmt ? fN(socialCashAmt) : "---"} CA$H
              </Typography>
            </Box>
          </div>
          <Button
            component={Link}
            variant="contained"
            className={classes.button}
            classes={{ label: classes.buttonLabel }}
            color="secondary"
            to='/redeem'
          >
            Cash Out
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}

BalanceOverview.propTypes = {};

export default BalanceOverview;

import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import { Container, Grid, Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import get from "lodash.get";
import UserContext from "../contexts/UserContext";
import { flureeFetch } from "../flureeFetch";
import TransactionItem from "../components/TransactionItem";
import TransactionList from "../components/TransactionList";
const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(3)
    },
    paddingBottom: theme.spacing(3),
    background: theme.palette.primary.light,
    width: "100%"
  },
  grid: {
    marginTop: theme.spacing(2)
  },
  title: {
    color: theme.palette.text.primary
  },
  list: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 5
    },
    marginTop: 20
  },
  redeemGrid: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    backgroundColor: '#009444',
    // box- shadow: 1px 1px 12px #00000029;
    borderRadius: 50,
    opacity: 1
  },
  buttonLabel: {
    ...theme.typography.button,
    color: '#FFF'
  }
}));

function Dashboard() {
  const classes = useStyles();
  const { user, blockData } = useContext(UserContext);
  const walletName = get(user, ["user/wallet", "wallet/name"]);
  const walletBalance = get(user, ["user/wallet", "wallet/balance"]);
  const walletEarnings = get(user, ["user/wallet", "wallet/earnings"])
  const blockOneDate = blockData["_block/instant"];
  const nowDate = new Date();
  const dayAgoDate = new Date().setDate(nowDate.getDate() - 1);
  const weekAgoDate = new Date().setDate(nowDate.getDate() - 7);
  const monthAgoDate = new Date().setMonth(nowDate.getMonth() - 1);
  const [dayEarnings, setDayEarnings] = useState(0);
  const [weekEarnings, setWeekEarnings] = useState(0);
  const [monthEarnings, setMonthEarnings] = useState(0);
  const [balance, setBalance] = useState(walletBalance);
  const [transactions, setTransactions] = useState(user['user/cashouts'])

  const getWalletStats = (
    walletName,
    blockOneDate,
    dayAgoDate,
    weekAgoDate,
    monthAgoDate
  ) => {
    const dayFlag = blockOneDate < dayAgoDate;
    const weekFlag = blockOneDate < weekAgoDate;
    const monthFlag = blockOneDate < monthAgoDate;
    const selectOne = ["?earnings", "?balance"];
    const where = [
      ["?wallet", "wallet/name", walletName],
      ["?wallet", "wallet/earnings", "?earnings"],
      ["?wallet", "wallet/balance", "?balance"]
    ];
    const firstWalletDate = new Date(blockOneDate + 1000000).toISOString();
    if (dayFlag) {
      selectOne.push("?earningsYesterday");
      where.push([
        "$fdbP1D",
        "?wallet",
        "wallet/earnings",
        "?earningsYesterday",
        { optional: true }
      ]);
    } else {
      selectOne.push("?earningsYesterday");
      where.push([
        "$fdb" + firstWalletDate,
        "?wallet",
        "wallet/earnings",
        "?earningsYesterday",
        { optional: true }
      ]);
    }
    if (weekFlag) {
      selectOne.push("?earningsLastWeek");
      where.push([
        "$fdbP1W",
        "?wallet",
        "wallet/earnings",
        "?earningsLastWeek",
        { optional: true }
      ]);
    } else {
      selectOne.push("?earningsLastWeek");
      where.push([
        "$fdb" + firstWalletDate,
        "?wallet",
        "wallet/earnings",
        "?earningsLastWeek",
        { optional: true }
      ]);
    }
    if (monthFlag) {
      selectOne.push("?earningsLastMonth");
      where.push([
        "$fdbP1M",
        "?wallet",
        "wallet/earnings",
        "?earningsLastMonth",
        { optional: true }
      ]);
    } else {
      selectOne.push("?earningsLastMonth");
      where.push([
        "$fdb" + firstWalletDate,
        "?wallet",
        "wallet/earnings",
        "?earningsLastMonth",
        { optional: true }
      ]);
    }
    const walletQuery = {
      selectOne: selectOne,
      where: where
    };
    return flureeFetch("/query", walletQuery).then(res => {
      if (res) {
        const [
          earnings,
          balance,
          earningsYesterday,
          earningsLastWeek,
          earningsLastMonth
        ] = res;
        setDayEarnings(earnings - (earningsYesterday || 0));
        setWeekEarnings(earnings - (earningsLastWeek || 0));
        setMonthEarnings(earnings - (earningsLastMonth || 0));
        setBalance(balance);
      } else {
        setDayEarnings(walletEarnings)
        setWeekEarnings(walletEarnings)
        setMonthEarnings(walletEarnings)
      }
    })
      .catch(err => {
        debugger
      })
  };

  // const getTransactionData = () => {
  //   const transactions = user['user/cashouts']
  //   setTransactions(transactions)
  //   const pendingTransactions = transactions.filter(txn = txn['transfer/status'] === 'Pending')
  //   if (pendingTransactions.length > 0) {
  //     Promise.all(pendingTransactions.map(txn => new Promise((resolve, reject) => {

  //     })))
  //   }
  // }

  useEffect(() => {
    getWalletStats(
      walletName,
      blockOneDate,
      dayAgoDate,
      weekAgoDate,
      monthAgoDate
    );
    // getTransactionData()
  }, []);

  return (
    <div className={classes.root}>
      <Container maxWidth={true}>
        <Typography variant="h1" className={classes.title}>
          Transactions
        </Typography>
        <Grid item xs={12} className={classes.redeemGrid}>
          <Button
            component={Link}
            variant='contained'
            className={classes.button}
            classes={{ label: classes.buttonLabel }}
            color='primary'
            to='/redeem'
          >
            Redeem Balance
          </Button>
        </Grid>
        <Grid container spacing={5} className={classes.grid}>
          <Grid item sm={12} md={4} xl={4} xs={12}>
            <TransactionItem amount={dayEarnings} text={"Earned Today"} />
          </Grid>
          <Grid item sm={12} md={4} xl={4} xs={12}>
            <TransactionItem amount={weekEarnings} text={"Earned This Week"} />
          </Grid>
          <Grid item sm={12} md={4} xl={4} xs={12}>
            <TransactionItem
              amount={monthEarnings}
              text={"Earned This Month"}
            />
          </Grid>
          {/* <Grid item sm={6} md={3} xl={3} xs={12}>
            <TransactionItem amount={balance} text={"Balance"} />
          </Grid> */}
          <Grid item xs={12} className={classes.list}>
            <TransactionList transactions={transactions} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Dashboard;

import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent } from "@material-ui/core";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { formatNumber as fN } from '../utils/functions'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    borderRadius: 30,
    minWidth: 200,
    maxWidth: 300,
    margin: "0 30px",
    [theme.breakpoints.down('lg')]: {
      margin: 'inherit'
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0 20px",
      minWidth: 120
    }
  },
  inner: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: "100%",
    height: '100%',
    textAlign: "center"
  },
  header: {
    textAlign: "center",
    color: theme.palette.secondary.main,
  },
  subheader: {
    color: theme.palette.text.primary
  },
  text: {
    textTransform: "none",
    color: theme.palette.text.primary,
    marginBottom: 20
    //fontSize: theme.typography.pxToRem(12)
  },
  content: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(12),
    height: '100%'

  }
}));

function RedeemItem({ amount, text, currency }) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <div className={classes.inner}>
          <Typography variant="h2" className={classes.text}>
            {text}
          </Typography>
          <div>
            <Typography variant="h1" className={classes.header}>
              {currency === 'USD' ? '$' : ''}{fN(amount)}
            </Typography>
            <Typography variant="body1" className={{ ...classes.header, ...classes.subheader }}>
              {currency}
            </Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

RedeemItem.propTypes = {
  amount: PropTypes.string,
  text: PropTypes.string,
  currency: PropTypes.string
};

export default RedeemItem;

import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Container } from "@material-ui/core";
import "../assets/css/help.css";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "80vh"
    //minwidth: 1376
  },
  heading: {
    display: "flex",
    justifyContent: "center",
    color: "#4096D2",
    marginBottom: 50,
    [theme.breakpoints.down("sm")]: {
      fontSize: 32
    }
  },
  subHeading: {
    display: "flex",
    justifyContent: "center",
    padding: "0 130px",
    color: theme.palette.text.primary,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      padding: "0 50px"
    }
  },
  button: {
    margin: "0 10px",
    color: "#eee",
    backgroundColor: "#EF4137",
    border: "none",
    [theme.breakpoints.down("sm")]: {
      margin: "0 10px"
    }
  },
  home: {
    color: "#EF4137",
    textDecoration: "underline",
    fontSize: 24,
    [theme.breakpoints.down("sm")]: {
      fontSize: 18
    }
  }
}));

function Help(props) {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Typography variant="h2" className={classes.heading}>
        HELP US HELP YOU!
      </Typography>

      <Typography variant="h4" className={classes.subHeading}>
        Reach out to us, either by joining us on Twitter and LinkedIn, or by
        sending us an email!
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          height: "30vh"
        }}
      >
        <a
          href="https://twitter.com/_FabricPay_"
          className={clsx(
            classes.button,
            "btn",
            "btn-lg",
            "btn-primary",
            "no-transform"
          )}
          target="_blank"
        >
          Follow us on Twitter!
        </a>
        <a
          href="https://linkedin.com/company/wearefabric"
          className={clsx(
            classes.button,
            "btn",
            "btn-lg",
            "btn-primary",
            "no-transform"
          )}
          target="_blank"
        >
          Connect with us on LinkedIn!
        </a>
        <a
          href="mailto:customerservice@wearefabric.io"
          className={clsx(
            classes.button,
            "btn",
            "btn-lg",
            "btn-primary",
            "no-transform"
          )}
          target="_blank"
        >
          Reach Out to Us by Email!
        </a>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Link to="/" className={classes.home}>
          Return Home
        </Link>
      </div>
    </Container>
  );
}

export default Help;

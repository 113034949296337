import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { fade } from "@material-ui/core/styles/colorManipulator";

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650
  },
  head: {
    fontWeight: "bold",
    // ...theme.typography.button
  },
  complete: {
    color: theme.palette.secondary.main
  },
  pending: {
    color: fade("#EF4137", 0.5)
  },
  denied: {
    color: "#EF4137"
  }
}));

function createData(id, date, scsh, amount, status) {
  return { id, date, scsh, amount, status };
}

const rows = [
  createData("12345678", "12/5/2019", 3000, 30, "Complete"),
  createData("54548545", "1/23/2020", 3000, 30, "Complete"),
  createData("78523512", "1/30/2020", 6000, 60, "Pending"),
  createData("78921321", "4/8/2019", 3000, 30, "Complete"),
  createData("12124545", "9/23/2019", 9000, 90, "Denied")
];

function TransactionList({ transactions }) {
  const [transactionsArray, setTransactionsArray] = useState(transactions || [])
  const classes = useStyles();
  const getStatusClass = status => {
    let result;
    switch (status) {
      case "Pending":
        result = classes.pending;
        break;
      case "Denied":
        result = classes.denied;
        break;
      default:
        result = classes.complete;
        break;
    }
    return result;
  };

  const rows = transactionsArray.reverse().slice(0, 8).map(txn => {
    const { _id } = txn
    const dateInitiated = new Date(txn['transfer/dateInitiated']).toLocaleDateString()
    const amountCASH = txn['transfer/amountCASH'].toString()
    const usd = txn['transfer/amountUSD'].toString()
    const amountUSD = usd.slice(0, usd.length - 2) + '.' + usd.slice(usd.length - 2)
    const status = txn['transfer/status']
    return createData(_id, dateInitiated, amountCASH, amountUSD, status)
  })

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="transaction">
        <TableHead>
          <TableRow>
            <TableCell className={classes.head}>Transaction ID#</TableCell>
            <TableCell align="left" className={classes.head}>
              Date
            </TableCell>
            <TableCell align="left" className={classes.head}>
              CA$H Withdrawn
            </TableCell>
            <TableCell align="left" className={classes.head}>
              USD Amount
            </TableCell>
            <TableCell align="left" className={classes.head}>
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                {row.id}
              </TableCell>
              <TableCell align="left">{row.date}</TableCell>
              <TableCell align="left">{row.scsh}</TableCell>
              <TableCell align="left">${row.amount}</TableCell>
              <TableCell align="left" className={getStatusClass(row.status)}>
                {row.status}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TransactionList;

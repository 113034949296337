import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { s3Bucket } from '../appConfig';
import get from 'lodash.get';
import { Popover, OverlayTrigger } from 'react-bootstrap'

const devPopover = (
      <Popover id="dev-popover">
            <Popover.Title as="h3">Uh-Oh!</Popover.Title>
            <Popover.Content>
                  This feature's still in development! Try again soon and see if it's working!
        </Popover.Content>
      </Popover>
)

export function encode(data) {
      var str = data.reduce(function (a, b) { return a + String.fromCharCode(b) }, '');
      return btoa(str).replace(/.{76}(?=.)/g, '$&\n');
}

class LeftSidebar extends Component {
      state = {

      }

      componentDidMount() {
            const profilePic = get(this.props.user, "user/profilePic");
            const profilePicPath = (profilePic.match(/^http/) ? profilePic : `https://s3.amazonaws.com/${s3Bucket}/${profilePic}`)
            this.setState({ profilePic: profilePicPath })
      }

      isActiveWallet(match, location) {
            if (location.pathname === "/account/view-wallet" || location.pathname === '/redeem'
                  || location.pathname === "/account"
                  || location.pathname === "/") {
                  return true
            }

            return false;
      }

      render() {
            const firstName = get(this.props.user, "user/firstName");
            const lastName = get(this.props.user, "user/lastName");
            const handle = get(this.props.user, "user/handle");

            return (
                  <aside id="leftsidebar" className="sidebar">
                        <h3 className="text-center" style={{ marginBottom: "0px", color: "#D48753" }}>Level 3</h3>
                        <div >
                              <ul className="list">
                                    <li style={{ backgroundColor: "#F4F4F4" }}>
                                          <div className="user-info">
                                                <div className="image" style={{ marginBottom: "8px" }}>
                                                      <a>
                                                            {
                                                                  this.state.profilePic
                                                                        ?
                                                                        <img src={this.state.profilePic} className="img-responsive img-circle" alt="User" />
                                                                        :
                                                                        null
                                                            }
                                                            <span className="online-status online"></span>
                                                      </a>
                                                </div>
                                                <div className="detail">
                                                      <h4>{firstName} {lastName}</h4>
                                                      <small>@{handle}</small>
                                                </div>
                                                <div className="row">
                                                      <div className="col-12">
                                                            <a title="facebook" href="/" onClick={(e) => e.preventDefault()} className=" waves-effect waves-block"><i className="fab fa-facebook"></i></a>
                                                            <a title="twitter" href="/" onClick={(e) => e.preventDefault()} className=" waves-effect waves-block"><i className="fab fa-twitter"></i></a>
                                                            <a onClick={(e) => e.preventDefault()} data-container="body" data-toggle="popover" data-placement="right" data-content="Uh-oh! Looks like that feature hasn't been implemented yet! Please check back soon!" title="instagram" href="/" className=" waves-effect waves-block"><i className="fab fa-instagram"></i></a>
                                                      </div>
                                                </div>
                                          </div>
                                    </li>
                                    <li>
                                          <NavLink activeClassName="active-nav"
                                                to="/redeem"
                                                isActive={this.isActiveWallet}>
                                                <div className="text-center nav-item" >
                                                      <h4>View Wallet</h4>
                                                </div>
                                          </NavLink>
                                          <NavLink activeClassName="active-nav" to="/account/view-requests">
                                                <div className="text-center nav-item">
                                                      <h4>View Requests</h4>
                                                </div>
                                          </NavLink>


                                          <NavLink activeClassName="active-nav" to="/account/level-up">
                                                <div className="text-center nav-item">
                                                      <h4>Level Up</h4>
                                                </div>
                                          </NavLink>

                                          <NavLink activeClassName="active-nav" to="/account/upcoming-purchases">
                                                <div className="text-center nav-item">
                                                      <h4>Upcoming Purchases</h4>
                                                </div>
                                          </NavLink>

                                          <NavLink activeClassName="active-nav" to="/account/settings">
                                                <div className="text-center nav-item">
                                                      <h4>Account Settings</h4>
                                                </div>
                                          </NavLink>
                                    </li>
                              </ul>
                        </div>
                  </aside>
            )
      }
}

export default LeftSidebar;
import React, { Component } from 'react';

class Cashtags extends Component {

    render(){
        return(
            <aside id="leftsidebar" className="sidebar">
                <h4>Trending Cashtags</h4>		  
                {/* <div className="col-lg-3"> */}
		 <a href="#">
		 <div className="storybox" style={{
                    background: `linear-gradient( rgba(34,34,34,0.78), rgba(34,34,34,0.78)), url(${require(`../assets/img/posts/2.gif`)}) no-repeat`,
		            backgroundSize: "cover",
                    backgroundPosition: "center center",
                    WebkitBackgroundSize: "cover",
                    MozBackgroundSize: "cover",
                    OBackgroundSize: "cover"}}>
          <div className="story-body text-center">
           <div className=""><img className="img-circle" src={require(`../assets/img/users/10.jpg`)} alt="user" /></div>
           <h4>Clifford Graham</h4>
           <p>2 hours ago</p>
          </div>		  
		 </div>
		 </a>
		
		 <a href="#">
            <div className="storybox" style={{
                        background: `linear-gradient( rgba(34,34,34,0.78), rgba(34,34,34,0.78)), url(${require(`../assets/img/posts/3.gif`)}) no-repeat`,
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                        WebkitBackgroundSize: "cover",
                        MozBackgroundSize: "cover",
                        OBackgroundSize: "cover"}}>
          <div className="story-body text-center">
           <div className=""><img className="img-circle" src={require(`../assets/img/users/13.jpg`)} alt="user" /></div>
           <h4>Eleanor Harper</h4>
           <p>4 hours ago</p>
          </div>		  
		 </div>
		 </a>
		
		 <a href="#">
         <div className="storybox" style={{
                        background: `linear-gradient( rgba(34,34,34,0.78), rgba(34,34,34,0.78)), url(${require(`../assets/img/posts/4.jpg`)}) no-repeat`,
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                        WebkitBackgroundSize: "cover",
                        MozBackgroundSize: "cover",
                        OBackgroundSize: "cover"}}>
          <div className="story-body text-center">
           <div className=""><img className="img-circle" src={require(`../assets/img/users/12.jpg`)} alt="user"/></div>
           <h4>Sean Coleman</h4>
           <p>5 hours ago</p>
          </div>		  
		 </div>
		 </a>
           </aside>	
        )
    }
}

export default Cashtags;
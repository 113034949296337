import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { serverURL } from "../appConfig";
// import { getToken } from "../flureeFetch";
import Font from "../constants/FontSize";
import { trackPromise } from "react-promise-tracker";

const checkStrength = (password) =>
  /((?=.*\d)(?=.*[A-Za-z])(?=.*[~!@#$%^&*\_\-\+=]).{12,40})/.test(password);

class PasswordChange extends React.Component {
  state = {
    password: "",
    passwordConf: "",
    submitted: false,
  };

  componentDidMount() {
    this.setState({ error: null, success: null });
  }

  handleChange = (e) => {
    // console.log(e.target.name, e.target.value);
    this.setState({ [e.target.id]: e.target.value });
  };

  submit = (e) => {
    e.preventDefault();
    const { password } = this.state;
    const { handle, token } = this.props;

    trackPromise(
      // getToken()
      //   .then((flureeToken) =>
      fetch(`${serverURL}update_password/${handle}/${token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          password,
          // flureeToken
        }),
      })
        // )
        .then((res) => {
          if (res.status >= 400) {
            throw new Error(
              "Invalid Token Provided. Please resubmit your request to change password."
            );
          }
          return res.json();
        })
        .then((json) => {
          this.setState({ error: null, success: { message: json.message } });
        })
        .then(() => {
          setTimeout(() => {
            this.props.history.push("/");
          }, 5000);
        })
        .catch((err) => {
          this.setState({ error: err, success: null });
        })
    );
  };

  render() {
    const { password, passwordConf, submitted } = this.state;
    const { handle, token, vw, vh, innerHeight, innerWidth } = this.props;
    const lineHeight = (size) => String(1.3 * size) + "px";
    let fs = Font.big;
    fs = innerWidth < 768 ? Font.med : fs;
    fs = innerWidth < 350 ? Font.small : fs;
    if (innerWidth < 1025) {
      return (
        <div
          style={{
            display: "flex",
            height: innerHeight,
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              height: "30%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p
              style={{
                color: "#4096D2",
                fontFamily: "PT Sans",
                fontSize: fs.h1,
                lineHeight: lineHeight(fs.h1),
                textAlign: "center",
              }}
            >
              RESET YOUR
              <br />
              PASSWORD
            </p>
          </div>
          <Form
            onSubmit={this.submit}
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              width: vw(70),
            }}
          >
            <Form.Group controlId="password" style={{ width: "100%" }}>
              <Form.Label
                style={{
                  fontFamily: "Open Sans",
                  fontSize: fs.p1,
                  color: "#282560",
                  lineHeight: lineHeight(fs.p1),
                }}
              >
                New Password
              </Form.Label>
              <Form.Control
                type="password"
                placeholder=""
                value={password}
                onChange={(e) => this.setState({ password: e.target.value })}
                style={{
                  borderWidth: 3,
                  borderColor: "#282560",
                  height: vh(5),
                  fontFamily: "PT Sans",
                  fontSize: fs.input,
                  fontStyle: "italic",
                }}
                onBlur={(e) => {
                  if (!checkStrength(password)) {
                    this.setState({
                      error: {
                        message:
                          "Your password must be at least 12 characters and include a letter, number, and symbol (~!@#$%^&*_-+=)",
                      },
                    });
                  } else {
                    this.setState({ error: null });
                  }
                }}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="passwordConf" style={{ width: "100%" }}>
              <Form.Label
                style={{
                  fontFamily: "Open Sans",
                  fontSize: fs.p1,
                  color: "#282560",
                  lineHeight: lineHeight(fs.p1),
                }}
              >
                Confirm Password
              </Form.Label>
              <Form.Control
                type="password"
                placeholder=""
                value={passwordConf}
                onChange={(e) => {
                  this.setState({ passwordConf: e.target.value });
                  if (password === e.target.value) {
                    this.setState({ error: null });
                  }
                }}
                onBlur={(e) => {
                  if (password !== passwordConf) {
                    this.setState({
                      error: {
                        message: "Your password must match your previous entry.",
                      },
                    });
                  } else {
                    this.setState({ error: null });
                  }
                }}
                style={{
                  borderWidth: 3,
                  borderColor: "#282560",
                  height: vh(5),
                  fontFamily: "PT Sans",
                  fontSize: fs.input,
                  fontStyle: "italic",
                }}
              ></Form.Control>
            </Form.Group>
            <div
              className="text-center"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: vh(3),
              }}
            >
              <Button
                className="brand-btn landing-btn"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "70%",
                  height: vh(5),
                  fontFamily: "PT Sans",
                  fontSize: fs.button,
                  padding: 0,
                }}
                type="submit"
                disabled={
                  !password ||
                  !passwordConf ||
                  password !== passwordConf ||
                  !checkStrength(password)
                }
              >
                Submit New Password
              </Button>
            </div>
          </Form>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "40%",
              width: vw(60),
              paddingBottom: 50,
              paddingTop: 40,
            }}
          >
            {this.state.success && this.state.success.message ? (
              <div
                className="text-center"
                style={{
                  color: "green",
                  fontSize: fs.button,
                  fontFamily: "Open Sans",
                  lineHeight: lineHeight(fs.button),
                }}
              >
                {this.state.success.message}
              </div>
            ) : (
              <div
                className="text-center"
                style={{
                  color: "red",
                  fontSize: fs.button,
                  fontFamily: "Open Sans",
                  lineHeight: lineHeight(fs.button),
                }}
              >
                {this.state.error && this.state.error.message}
              </div>
            )}
            <div className="text-center">
              <Link to="/" className="btn" style={{ color: "#EF4137", fontFamliy: "PT Sans" }}>
                <p className="form-text brand-link" style={{ fontSize: fs.p1, color: "#EF4137" }}>
                  Return Home
                </p>
              </Link>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div style={{ margin: "40px auto", maxWidth: 900, width: "100%" }}>
        <div style={{ margin: "auto", width: "70%" }}>
          <h3 className="text-center">Update Your Password</h3>
          <div>
            {this.state.error ? (
              <div className="text-center" style={{ color: "red", fontSize: "14px" }}>
                {this.state.error.message}
              </div>
            ) : null}
            {this.state.success ? (
              <div
                className="text-center"
                style={{ color: "green", margin: "20px 0px", fontSize: "14px" }}
              >
                {this.state.success.message}
              </div>
            ) : null}
          </div>
          <div style={{ marginTop: "20px" }}>
            <Form onSubmit={this.submit}>
              <Form.Group controlId="password">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="New Password"
                  value={password}
                  onChange={this.handleChange}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="passwordConf">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Confirm Password"
                  value={passwordConf}
                  onChange={(e) => {
                    this.setState({ passwordConf: e.target.value });
                    if (password === e.target.value) {
                      this.setState({ error: null });
                    }
                  }}
                  onBlur={(e) => {
                    if (password !== passwordConf) {
                      this.setState({
                        error: {
                          message: "Your password must match your previous entry.",
                        },
                      });
                    } else {
                      this.setState({ error: null });
                    }
                  }}
                ></Form.Control>
              </Form.Group>
              <div className="text-center">
                <Button
                  type="submit"
                  disabled={!password || !passwordConf || password !== passwordConf}
                >
                  Submit New Password
                </Button>
              </div>
            </Form>
          </div>
          <div style={{ padding: "20px", marginTop: "15px" }}>
            <h2
              className="text-center"
              style={{
                width: "100%",
                borderBottom: "1px solid #000",
                lineHeight: "0.1em",
                margin: "10px 0 20px",
              }}
            >
              <span style={{ background: "#FFF", padding: "0 10px" }}>or</span>
            </h2>
          </div>
          <div className="text-center" style={{ marginTop: "5px" }}>
            <Link to="/login" className="btn btn-lg" style={{ color: "#2C3E50" }}>
              Return to Login
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(PasswordChange);

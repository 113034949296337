import React from 'react';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import coins from '../assets/img/dashboard/coins@2x.png';

const useStyles = makeStyles((theme) => ({
  root: {
    // minWidth: 300,
    width: '100%',
    borderRadius: 10,
    boxShadow: '0px 1px 12px #E3E3E3',
    minHeight: 140,
  },
  content: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'stretch',
    width: '100%',
    marginTop: 20
  },
  earnings: {
    textAlign: 'left',
    font: 'normal normal bold 36px/47px Futura',
    color: '#282560',
    width: '55%',
    [theme.breakpoints.down('md')]: {
      fontSize: 24
    }
  },
  duration: {
    font: 'normal normal bold 14px/19px Open Sans',
    color: '#282560',
    letterSpacing: 0
  },
  image: {
    background: `transparent url(${coins}) 0% 0% no-repeat padding-box`,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    opacity: 1,
    maxWidth: 67,
    width: '40%',
    // height: '100%'
  }
}));
export default function EarningsCard({ earnings, duration }) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <div className={classes.text}>
          <Typography className={classes.earnings}>{earnings}</Typography>
          <Typography className={classes.duration}>
            CA$H earned {duration}
          </Typography>
        </div>
        <div className={classes.image} />
      </CardContent>
    </Card>
  );
}

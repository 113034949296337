import React, { Component } from "react";
import { Button, Form } from "react-bootstrap";
import { Steps } from "./Register";
import { flureeFetch, getToken } from "../flureeFetch";
import { serverURL } from "../appConfig";
import get from "lodash.get";
import TagsInput from "react-tagsinput";
import { trackPromise } from "react-promise-tracker";
import "react-tagsinput/react-tagsinput.css";

class StepOne extends Component {
  state = {};

  componentDidMount() {}

  submit() {
    const { name, phoneNumber, address } = this.state;
    const transaction = [
      {
        _id: "company$1",
        name: name,
        phoneNumber: phoneNumber,
        address: address,
      },
    ];
    const authToken = getToken();
    flureeFetch("transact", transaction, authToken)
      .then((res) => {
        const _id = get(res, ["tempids", "company$1"]);
        this.props.setId(_id);
      })
      .then((res) => this.props.changeStep(2))
      .catch((err) => this.setState({ error: err }));
  }

  render() {
    const { name, phoneNumber, address } = this.state;

    return (
      <div>
        <div className="col-xs-3"></div>
        <div className="col-xs-6">
          <h3>Create Your Company</h3>
          <div>
            {this.state.error ? (
              <div style={{ color: "red", marginTop: "20px", fontSize: "14px" }}>
                <p>{JSON.stringify(this.state.error.message)}</p>
              </div>
            ) : null}
          </div>
          <p>Step 1 - Set Your company's name, phone number, and address.</p>
          <Form>
            <Form.Group controlId="name">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Company Name"
                value={name}
                onChange={(e) => this.setState({ name: e.target.value })}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="phoneNumber">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                value={phoneNumber}
                placeholder="+1 (234) 567-8910"
                onChange={(e) => this.setState({ phoneNumber: e.target.value })}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="address">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Address"
                value={address}
                onChange={(e) => this.setState({ address: e.target.value })}
              ></Form.Control>
            </Form.Group>
          </Form>
          <div className="text-center">
            <Button onClick={() => this.submit()} disabled={!name || !phoneNumber || !address}>
              Submit
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

class StepTwo extends Component {
  state = {
    tags: [],
  };

  componentDidMount() {}

  handleFile(e) {
    const reader = new FileReader();
    const file = e.target.files[0];

    if (file) {
      reader.onload = (upload) => {
        this.setState({
          data_uri: upload.target.result,
          filetype: file.type,
        });
      };

      reader.readAsDataURL(file);
    }
  }

  submit() {
    const { tags, data_uri, filetype } = this.state;
    const { id } = this.props;

    if (!data_uri || !filetype || !tags) {
      this.setState({ error: "You must complete all fields." });
      return;
    }

    let fileSuffix;
    if (filetype === "image/jpeg") {
      fileSuffix = ".jpeg";
    } else if (filetype === "image/png") {
      fileSuffix = ".png";
    } else {
      this.setState({ err: "" });
    }

    if (fileSuffix !== undefined && id !== undefined) {
      const fileName = `company/${id}/logo${fileSuffix}`;
      // fetch(`${serverURL}s3/upload-file`, {
      //     method: "POST",
      //     headers: { 'Content-Type': 'application/json'},
      //     body: JSON.stringify({
      //         buffer: data_uri,
      //         name: fileName,
      //         type: filetype
      // })})
      this.props.beginLoading("Uploading Logo...");
      trackPromise(
        fetch(`${serverURL}generatepresignedurl`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            name: fileName,
            type: filetype,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            if (!res.success) {
              throw new Error(res.message);
              return;
            }
            const buf = new Buffer(data_uri.replace(/^data:\w+\/\w+;base64,/, ""), "base64");
            return fetch(res.urls[0], {
              method: "PUT",
              headers: { "Content-Type": filetype },
              reportProgress: true,
              body: buf,
            });
          })
          .then((res) => {
            let transaction = [
              {
                _id: id,
                tags: tags,
                logo: fileName,
              },
            ];
            this.props.endLoading("Uploading Logo...");
            const authToken = getToken();
            flureeFetch("transact", transaction, authToken)
              .then((res) => this.props.history.push("/"))
              .catch((err) => this.setState({ error: err }));
          })

          .catch((err) => this.setState({ err: JSON.stringify(err) }))
      );
    }
  }

  render() {
    const { data_uri, filetype, tags } = this.state;
    return (
      <div>
        <div className="col-xs-3"></div>
        <div className="col-xs-6">
          <h3>Create Your Company</h3>
          <p>Step 2 - Upload your company's logo and set your tags.</p>
          <Form>
            <Form.Group controlId="logo">
              <Form.Label>Logo</Form.Label>
              {this.state.data_uri ? (
                <img
                  style={{ maxHeight: "100px", display: "block", maxWidth: "100px" }}
                  src={this.state.data_uri}
                />
              ) : null}
              <Form.Control type="file" onChange={(e) => this.handleFile(e)}></Form.Control>
            </Form.Group>
            <Form.Group controlId="tags">
              <Form.Label>Tags</Form.Label>
              <TagsInput value={tags} onChange={(tags) => this.setState({ tags: tags })} />
            </Form.Group>
          </Form>
          <div className="text-center">
            <Button onClick={() => this.submit()} disabled={!data_uri || !filetype || !tags}>
              Submit
            </Button>
          </div>
          {this.state.error ? <div> {this.state.error} </div> : null}
        </div>
      </div>
    );
  }
}

class AddCompany extends Component {
  state = {
    step: 1,
  };

  render() {
    return (
      <div>
        <div className="row">
          {/* <div className="col-xs-9" style={{height: "100px"}}>
                        <a className="navbar-brand" href="index.html" style={{paddingTop: 3, paddingLeft: 15}}>
                            <img src={require("../assets/img/fabric_logo_lg.png")} height="55" alt="We are Fabric" />
                        </a>
                    </div> */}
          <div className="col-sm-9"></div>
          <div className="col-sm-3" style={{ marginTop: "20px" }}>
            <Button onClick={() => this.props.history.push("/")}>Back to Admin Console</Button>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-3"></div>
          <div className="col-xs-6">
            <Steps step={this.state.step} stepMax={2} />
          </div>
          <div className="col-xs-3"></div>
        </div>
        <div className="row">
          {this.state.step === 1 ? (
            <StepOne
              changeStep={(s) => this.setState({ step: s })}
              setId={(id) => this.setState({ id: id })}
            />
          ) : null}
          {this.state.step === 2 ? (
            <StepTwo
              {...this.props}
              changeStep={(s) => this.setState({ step: s })}
              id={this.state.id}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default AddCompany;

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Box,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  content: {
    width: '100%',
    textAlign: 'center',
    marginTop: 15,
    marginBottom: 30
  },
  inner: {
    width: '100%',
    textAlign: 'center'
  },
  title: {
    textAlign: 'center',
    marginTop: 30
  },
  button: {
    font: 'normal normal bold 16px/21px Futura',
    borderRadius: 30,
    maxWidth: 220,
    width: '100%',
    border: '3px solid #EF4137',
    color: '#EF4137',
    backgroundColor: 'transparent',
    // padding: '20px',
    height: 55,
    '&:hover': {
      backgroundColor: '#EF4137',
        color: '#FFFFFF'
    }
  },
  buttonLabel: {
    color: '#EF4137',
    font: 'normal normal bold 16px/21px Futura'
  },
  subheader: {
    fontSize: theme.typography.pxToRem(12)
  }
}));

function ResetPassword(props) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {/* <CardHeader
        title="Reset Password"
        subheader="Request a new password link to be sent to your email address."
        // classes={{ subheader: classes.subheader }}
        titleTypographyProps={{ variant: 'h1' }}
        subheaderTypographyProps={{ variant: 'body1' }}
      /> */}
      <Typography className={classes.title} variant="h1">
        Reset Password
      </Typography>
      <Typography className={classes.content} variant="body1">
        Request a new password link to be sent to your email address.
      </Typography>
      <div className={classes.inner}>
        <button
          className={classes.button}
          classes={{ label: classes.buttonLabel }}
          onClick={() => props.history.push('/reset')}
        >
          Reset Password
        </button>
      </div>
    </Box>
  );
}

ResetPassword.propTypes = {};

export default withRouter(ResetPassword);

import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { flureeFetch } from '../flureeFetch';
import schemaTx from '../bootstrap/schema';
import generateUserTx from '../bootstrap/users';
import { tx1, tx2, tx3, tx4, tx5, tx6, tx7 } from '../bootstrap/vanessaBalanceChanges';
import categoriesTx from '../bootstrap/categories';
import adTx from '../bootstrap/ads';
import { generateKeyPair, getSinFromPublicKey } from 'fluree-cryptography';

// import data from '../bootstrap/images';

const newDb = { "db/id": "fabric/mvp4" }

const wait = ms => new Promise((r, j)=>setTimeout(r, ms))

class Initiate extends Component {
    state = {
        loading: false
    }

    initiateApp(){
        this.setState({loading: true})
        // flureeFetch("/new-db", newDb)
        // .then(res => wait(1000))
        flureeFetch("/transact", schemaTx)
        .then(res => flureeFetch("/transact", categoriesTx))
        .then(res => {
            const { privateKey, publicKey } = generateKeyPair();
            window.alert(`!!! IMPORTANT !!!\n\nYour new admin account has the following private and public keys\n\nPRIVATE KEY: ${privateKey}\nPUBLIC KEY: ${publicKey}\n\nIt is important that you store this data in a secure location.`)
            const authId = getSinFromPublicKey(publicKey);
            const userTx = generateUserTx(authId)
            return flureeFetch("/transact", userTx)})
        // .then(res => flureeFetch("/transact", tx1))
        // .then(res => flureeFetch("/transact", tx2))
        // .then(res => flureeFetch("/transact", tx3))
        // .then(res => flureeFetch("/transact", tx4))
        // .then(res => flureeFetch("/transact", tx5))
        // .then(res => flureeFetch("/transact", tx6))
        // .then(res => flureeFetch("/transact", tx7))
        .then(res => flureeFetch("/transact", adTx))
        .then(res => this.setState({loading: false}, () => this.props.refreshUser()))
        .catch(err => 
            this.setState({err: err, loading: false}, () => this.props.refreshUser()))
    } 

    render(){
        return(
            <div>
                <div className="row">
                    <div className="col-xs-3" style={{height: "100px"}}>
                        <a className="navbar-brand" href="index.html" style={{paddingTop: 3, paddingLeft: 15}}>
                        <img src={require("../assets/img/fabric_logo_lg.png")} height="55" alt="We are Fabric" />
                        </a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 text-center">
                        <Button disabled={this.state.loading} onClick={() => this.initiateApp()}>Initiate App</Button>
                        <br/>
                        {
                            this.state.error 
                            ?
                            this.state.error
                            :
                            null
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default Initiate;
import { createContext } from "react";

const UserContext = createContext({
  user: {},
  blockData: {},
  refreshUser: {},
  admin: false,
  loading: false
});

export const UserProvider = UserContext.Provider;
export const UserConsumer = UserContext.Consumer;
export default UserContext;

import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import { UserProvider } from "../contexts/UserContext";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import NavBar from "./NavBar";
import HeaderBar from "./HeaderBar";
import Post from "../screens/Post";
import Account from "../screens/Account";
import Dashboard from "../screens/Dashboard";
import PasswordReset from "./PasswordReset";
import PasswordChange from "./PasswordChange";
import Help from "./Help";
import Profile from "../screens/Profile";
import Wallet from "./account/Wallet";
import Ad from "../screens/Ad";
import LevelUp from "../screens/LevelUp";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import Paper from "@material-ui/core/Paper";
import LoadingSpinner from "./LoadingSpinner";
import Transactions from "../screens/Transactions";
import Redeem from "../screens/Redeem";
import ApplyModal from "./ApplyModal";

const drawerWidth = 350;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.down("sm")]: {
      minHeight: "auto",
    },
  },
  // root: {
  //   minHeight: "100vh",
  //   display: "flex",
  //   "@media all and (-ms-high-contrast:none)": {
  //     height: 0 // IE11 fix
  //   }
  // },
  main: {
    display: "flex",
    flex: 1,
    // minHeight: 901,
    [theme.breakpoints.down("sm")]: {
      minHeight: "auto",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
    //height: "100%"
  },
  drawerPaper: {
    width: drawerWidth,
    position: "relative",
    //borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
    borderRight: 0,
    height: "100%",
  },
  drawerDocked: {
    height: "100%",
  },
  content: {
    flexGrow: 1,
    margin: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      width: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1),
      width: "auto",
    },
  },
  aside: {
    height: "100%",
  },
}));

function Authenticated(props) {
  const { refreshUser, user, blockData, beginLoading, endLoading } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const threshold = 5000;
  const [modalOpen, setModalOpen] = useState(false);

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleMenuClick = (event) => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    // console.log({user})
    if (user["user/applied"] === false && user["user/wallet"]["wallet/earnings"] >= threshold) {
      setModalOpen(true);
    }
  }, [user]);

  const drawer = (
    <Paper component="aside" className={classes.aside}>
      <NavBar />
    </Paper>
  );

  return (
    <UserProvider
      value={{
        beginLoading: props.beginLoading,
        endLoading: props.endLoading,
        user: props.user,
        blockData: props.blockData,
        refreshUser: props.refreshUser,
        handleMenuClick: handleMenuClick,
      }}
    >
      <LoadingSpinner />

      <CssBaseline />

      <BrowserRouter>
        <div className={classes.root}>
          <ApplyModal open={modalOpen} onClose={handleClose} />
          <HeaderBar></HeaderBar>
          <div className={classes.main}>
            <div className={classes.drawer}>
              {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
              <Hidden mdUp>
                <Drawer
                  variant="temporary"
                  open={mobileOpen}
                  onClose={handleMenuClick}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                >
                  {drawer}
                </Drawer>
              </Hidden>
              <Hidden smDown>
                <Drawer
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  className={classes.drawerDocked}
                  variant="permanent"
                  open
                  onClose={handleMenuClick}
                >
                  {drawer}
                </Drawer>
              </Hidden>
            </div>

            <Switch>
              <Route
                path="/user/:handle/post/new"
                render={() => (
                  <Post {...props} refreshUser={refreshUser} user={user} blockData={blockData} />
                )}
              />
              <Route
                path="/user/:handle/post/:postId"
                render={(props) => (
                  <Post {...props} refreshUser={refreshUser} user={user} blockData={blockData} />
                )}
              />
              <Route
                path="/user/:_id/post"
                render={(props) => (
                  <Post {...props} refreshUser={refreshUser} user={user} blockData={blockData} />
                )}
              />
              <Route
                path="/user/posts"
                render={(props) => (
                  <Post
                    {...props}
                    all={true}
                    refreshUser={refreshUser}
                    user={user}
                    blockData={blockData}
                  />
                )}
              />
              <Route
                path="/explore"
                render={(props) => (
                  <Post
                    {...props}
                    all={true}
                    refreshUser={refreshUser}
                    user={user}
                    blockData={blockData}
                  />
                )}
              />
              <Route
                path="/account/:subpage"
                render={(props) => (
                  <Account {...props} refreshUser={refreshUser} user={user} blockData={blockData} />
                )}
              />
              <Route
                path="/account"
                render={(props) => (
                  <Account {...props} refreshUser={refreshUser} user={user} blockData={blockData} />
                )}
              />
              <Route
                path="/smart-profile"
                render={(props) => (
                  <Profile
                    {...props}
                    refreshUser={refreshUser}
                    user={user}
                    blockData={blockData}
                    beginLoading={beginLoading}
                    endLoading={endLoading}
                  />
                )}
              />
              <Route
                path="/view-wallet"
                render={(props) => (
                  <Redeem {...props} refreshUser={refreshUser} user={user} blockData={blockData} />
                )}
              />
              <Route
                path="/level-up"
                render={(props) => (
                  <LevelUp {...props} refreshUser={refreshUser} user={user} blockData={blockData} />
                )}
              />
              <Route
                path="/redeem"
                render={(props) => (
                  <Redeem {...props} refreshUser={refreshUser} user={user} blockData={blockData} />
                )}
              />
              <Route
                path="/ad/:_id"
                render={(props) => (
                  <Ad {...props} refreshUser={refreshUser} user={user} blockData={blockData} />
                )}
              />
              <Route
                path="/help"
                render={(props) => (
                  <Help {...props} refreshUser={refreshUser} user={user} blockData={blockData} />
                )}
              />
              <Route
                path="/reset"
                exact
                render={(props) => (
                  <PasswordReset refreshUser={refreshUser} user={user} blockData={blockData} />
                )}
              />
              <Route
                path="/reset/:handle/:token"
                render={(props) => (
                  <>
                    <PasswordChange
                      {...props}
                      handle={props.match.params.handle}
                      token={props.match.params.token}
                      refreshUser={refreshUser}
                    />
                  </>
                )}
              />
              <Route path="/transactions" render={(props) => <Transactions {...props} />} />
              <Route
                path="/"
                render={(props) => (
                  <Dashboard
                    {...props}
                    refreshUser={refreshUser}
                    user={user}
                    blockData={blockData}
                    beginLoading={beginLoading}
                    endLoading={endLoading}
                  />
                )}
              />
            </Switch>
          </div>
        </div>
      </BrowserRouter>
    </UserProvider>
  );
}

Authenticated.propTypes = {
  refreshUser: PropTypes.object,
  user: PropTypes.object,
  blockData: PropTypes.object,
};

export default Authenticated;

import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import { Container, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import RedeemBalance from "../components/RedeemBalance";
import RedeemItem from "../components/RedeemItem";
import get from "lodash.get";
import UserContext from "../contexts/UserContext";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(3)
    },
    paddingBottom: theme.spacing(3),
    background: theme.palette.primary.light,
    width: "100%"
  },
  grid: {
    marginTop: theme.spacing(2)
  },
  title: {
    color: theme.palette.text.primary
  },
  subtitle: {
    color: theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14
    }
  },
  subtext: {
    color: theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12
    }
  },
  redeem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: 0
  },
  heading: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    marginBottom: 20
  },
  equal: {
    alignSelf: 'center',
    color: theme.palette.text.primary,
    fontSize: 60,
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      margin: "50px 0 0 0",
      fontSize: 40
    }
  },
  balance: {
    justifyContent: 'center',
    marginTop: 20
  }
}));

function Redeem() {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const walletBalance = get(user, ["user/wallet", "wallet/balance"]);
  const [socialCashAmt, setSocialCashAmt] = useState(walletBalance);

  return (
    <div className={classes.root}>
      <Container maxWidth={true}>
        <Typography variant="h1" className={classes.title}>
          Redeem CA$H
        </Typography>
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12} className={classes.heading}>
            <Typography variant="h1" className={classes.subtitle}>
              Cash Out Your CA$H Balance
            </Typography>
            <Typography variant="body1" className={classes.subtext}>
              Every 100 CA$H Tokens are redeemable for $1 USD!
            </Typography>
          </Grid>
          {/* <Grid item xs={2}></Grid> */}
          <Grid xs={12} className={classes.redeem}>
            <RedeemItem
              text="CA$H Balance"
              currency="CA$H"
              amount={walletBalance}
            ></RedeemItem>
            <Typography variant="h2" className={classes.equal}>
              =
            </Typography>
            <RedeemItem
              text="USD Value"
              amount={walletBalance / 100}
              currency="USD"
            ></RedeemItem>
          </Grid>
          {/* <Grid item xs={2}></Grid> */}
          <Grid item xs={12} className={clsx(classes.redeem, classes.balance)}>
            <RedeemBalance></RedeemBalance>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Redeem;

import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Link, withRouter } from "react-router-dom";
import {
  flureeFetch,
  // getToken
} from "../flureeFetch";
import { serverURL } from "../appConfig";
import { Button, Form } from "react-bootstrap";
import { Container, Card, CardContent, Grid } from "@material-ui/core";
import { trackPromise } from "react-promise-tracker";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    background: theme.palette.primary.light,
    width: "100%",
  },
  content: {
    borderRadius: 20,
  },
  grid: {
    marginTop: theme.spacing(2),
  },
  inner: {
    width: "100%",
    textAlign: "center",
  },
}));

function PasswordReset() {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const submit = (e) => {
    e.preventDefault();

    setError(null);
    setSuccess(null);

    // let query = {
    //   select: ["user/email", "user/googleId"],
    //   from: ["user/email", email],
    // };
    // const postBody = { email };
		const postBody = { email: email.toLowerCase() };
    trackPromise(
      fetch(`${serverURL}auth/verify`, {
        method: "POST",
        body: JSON.stringify(postBody),
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          //   .then((data) => {
          //     debugger;
          //     if (data.available) {
          //       this.props.setUser({
          //         ...this.state.user,
          //         _id: "user$1",
          //         email: email,
          //         password: password,
          //       });
          //       this.props.changeStep(1);
          //     } else {
          //       this.setState({
          //         error: {
          //           message: `There is already an account associated with this email: ${email}. Did you mean to sign in?`,
          //         },
          //       });
          //     }
          //   })
          //   .catch((err) => {
          //     debugger;
          //     console.log(err);
          //   });
          // flureeFetch("query", query).then((res) => {
          if (res) {
            //this.props.beginLoading("Sending Password Reset Email...")

            // getToken()
            //   .then((token) =>
            fetch(`${serverURL}reset/${email}`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": window.location.host,
              },
              // body: JSON.stringify({
              //   token
              // })
            })
              // )
              .then((res) => {
                if (res.status === 404) {
                  setError({
                    message: "User not found. Please be sure you have the correct email address.",
                  });
                } else {
                  setSuccess({
                    message: `An email has been sent to ${email} with instructions for password reset.`,
                  });
                  setTimeout(() => {
                    // this.props.endLoading("Sending Password Reset Email...");
                    setEmail("");
                    setPassword("");
                  }, 2500);
                }
              })
              .catch((err) => {
                setError({ message: err.message });
                //this.props.endLoading("Sending Password Reset Email...")
              });
          } else {
            setError({
              message: "That email address is not associated with an active user",
            });
          }
        })
    );
  };

  return (
    <div className={classes.root}>
      <Container maxWidth={true}>
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={12}>
            <Card className={classes.content}>
              <CardContent>
                <div className={classes.inner}>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="col-xs-4" />
                    <div className="col-xs-4 ">
                      <h3 className="text-center">Reset Your Password</h3>
                      <div>
                        {error ? (
                          <div className="text-center" style={{ color: "red", fontSize: "14px" }}>
                            {error.message}
                          </div>
                        ) : null}
                        {success ? (
                          <div
                            className="text-center"
                            style={{
                              color: "green",
                              margin: "20px 0px",
                              fontSize: "14px",
                            }}
                          >
                            {success.message}
                          </div>
                        ) : null}
                      </div>
                      <div style={{ marginTop: "20px" }}>
                        <Form onSubmit={submit}>
                          <Form.Group controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder='e.g. "JohnDoe@gmail.com"'
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            ></Form.Control>
                            <small id="emailHelp" className="form-text text-muted">
                              Please provide the email address associated with your account.
                            </small>
                          </Form.Group>
                          <div className="text-center">
                            <Button type="submit" disabled={!email}>
                              Reset Password
                            </Button>
                          </div>
                        </Form>
                      </div>
                      <div style={{ padding: "20px", marginTop: "15px" }}>
                        <h2
                          className="text-center"
                          style={{
                            width: "100%",
                            borderBottom: "1px solid #000",
                            lineHeight: "0.1em",
                            margin: "10px 0 20px",
                          }}
                        >
                          <span style={{ background: "#FFF", padding: "0 10px" }}>or</span>
                        </h2>
                      </div>
                      <div className="text-center" style={{ marginTop: "5px" }}>
                        <Link to="/login" className="btn btn-lg" style={{ color: "#2C3E50" }}>
                          Return to Login
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default PasswordReset;

import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, TextField, Button, InputAdornment } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import get from "lodash.get";
import UserContext from "../contexts/UserContext";
import { formatNumber as fN, deformatNumber as dfN } from '../utils/functions'
import { serverURL } from '../appConfig'
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";
// import { getToken } from "../flureeFetch";

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 20,
    padding: 30
  },
  content: {},
  inner: {
    width: "100%",
    textAlign: "center"
  },
  buttonLabel: {
    textTransform: "none",
    color: "#FFFFFF"
  },
  button: {
    borderRadius: 20,
    width: 150
  },
  subtext: {
    color: theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12
    }
  },
  inputContainer: {
    margin: 20,
    color: 'rgba(0, 148, 68, 0.4)'
  },
  buttonContainer: {
    margin: 20,
    paddingTop: 20
  },
  // formRoot: {
  //   color: 'rgba(0, 148, 68, 0.4)'
  // },
  textRoot: {
    color: 'rgba(0, 148, 68, 0.4)',
    width: '40%'
  },
  textRoot2: {
    '& input': {
      ...theme.typography.button
    },
    '& input ~ fieldset': {
      borderColor: 'rgba(0, 148, 68, 0.4)',
      borderWidth: 3,
    },
    '& input:valid:hover ~ fieldset': {
      borderColor: 'rgb(0, 148, 68)',
      borderWidth: 3,
    },
    '& input:invalid ~ fieldset': {
      borderColor: 'yellow',
      borderWidth: 3,
    },
    '& input:valid:focus ~ fieldset': {
      borderWidth: 3,
      borderColor: 'rgb(0, 148, 68)',
      padding: '4px !important', // override inline-style
    },
    '& input:invalid:focus ~ fieldset': {
      borderWidth: 3,
      borderColor: 'blue',
      padding: '4px !important', // override inline-style
    }
  },

  textField: {
    height: 10,
    border: '3px solid rgba(0, 148, 68, 0.4)',
    [theme.breakpoints.down("sm")]: {
      height: 6
    }
  },
  inputAdornment: {
    '& p': {
      ...theme.typography.button
    }
  },
  notchedOutline: {
    '&.Mui-error fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: '#EF4137 !important',
      borderWidth: 3
    }
  },
  linkButton: {
    borderRadius: "20px !important",
    border: "none !important",
    width: 150,
    background: "#4096D2 !important",
    fontSize: theme.typography.pxToRem(15),
    ...theme.typography.button,
    color: "#FFFFFF",
    '& .MuiButton-label': {
      color: "#FFF"
    }
  }
}));

function RedeemBalance() {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const walletBalance = get(user, ["user/wallet", "wallet/balance"]);
  const [socialCashAmt, setSocialCashAmt] = useState(walletBalance);
  const [formState, setFormState] = useState({
    usdValue: '',
    value: '',
    touched: false
  })
  //user['user/plaid'] && user['user/plaid']["plaid/dwollaFundingSourceLocation"]
  const isValid = !!formState.value && typeof dfN(formState.value) === 'number' && dfN(formState.value) <= walletBalance

  const handleFieldChange = event => {
    event.persist()
    let value = dfN(event.target.value)
    // debugger
    if (value !== value) {
      return
    }
    let usdValue = value / 100
    // usdValue = usdValue % 1 === 0 ? usdValue : usdValue.toFixed(2)
    // debugger
    usdValue = usdValue === 0 ? '' : fN(usdValue)
    value = value === 0 ? '' : fN(value)

    setFormState({
      value,
      usdValue,
      touched: true
    })
  }

  const handleCashOut = async (event) => {
    event.preventDefault()
    const userID = user._id
    const walletID = user['user/wallet']._id
    const userFundingSource = user['user/plaid']["plaid/dwollaFundingSourceLocation"]
    const [amountUSD, amountCASH] = [formState.value, formState.value]
    // const flureeToken = await getToken()
    const body = {
      userID,
      userFundingSource,
      amountUSD,
      amountCASH,
      walletID,
      // flureeToken
    }
    trackPromise(fetch(`${serverURL}initiate_redeem_balance`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body)
    })
      .then(res => {
        // console.log({ res })
        debugger
        window.location.reload()
      })
      .catch(err => {
        console.log({ err })
        return
      }))
  }

  return (
    <Card className={classes.root}>
      <CardContent className={classes.inner}>
        <Typography variant="body1" className={classes.subtext}>
          {user['user/plaid'] && user['user/plaid']["plaid/dwollaFundingSourceLocation"] ? "Enter the amount of CA$H you'd like to redeem. (Whole numbers only)" : "In order to redeem CA$H as USD, please first connect to your financial institution."}
        </Typography>
        <form className={classes.formRoot}>
          <div className={classes.inputContainer}>
            <TextField
              variant="outlined"
              // color="secondary"
              disabled={!(user['user/plaid'] && user['user/plaid']["plaid/dwollaFundingSourceLocation"])}
              className={classes.textRoot}
              classes={{ root: classes.textRoot2 }}
              error={!!formState.touched && !isValid}
              onChange={handleFieldChange}
              value={formState.value}
              InputProps={{ classes: { root: classes.notchedOutline }, endAdornment: <InputAdornment position="end" disablePointerEvents classes={{ root: classes.inputAdornment }}>{formState.usdValue ? `($${formState.usdValue})` : ''}</InputAdornment> }}
            // InputProps={{ classes: { input: classes.textField, root: classes.textRoot } }}
            ></TextField>
          </div>
          <div className={classes.buttonContainer}>
            {user['user/plaid'] && user['user/plaid']["plaid/dwollaFundingSourceLocation"] ? <Button
              variant="contained"
              className={classes.button}
              classes={{ label: classes.buttonLabel }}
              color="secondary"
              disabled={!isValid}
              onClick={handleCashOut}
            >
              Cash Out
            </Button> : <Button
                component={Link}
                variant="contained"
                className={classes.linkButton}
                classes={{ label: classes.cashoutButtonLabel }}
                color="primary"
                to='/smart-profile'
              >
                Link Bank
            </Button>}
          </div>
        </form>
      </CardContent>
    </Card>
  );
}

RedeemBalance.propTypes = {};

export default RedeemBalance;

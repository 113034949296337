import React, { Component } from 'react';
import { Button, Form, Modal, Row } from 'react-bootstrap';
import LeftSidebar from '../components/LeftSidebar';
import RightSidebar from '../components/RightSidebar';
import { flureeFetch } from '../flureeFetch';
import Select from 'react-select';
import get from 'lodash.get';
import fetch from 'isomorphic-fetch';
import { s3Bucket, serverURL } from '../appConfig';
import ReactCrop from 'react-image-crop'
import * as loadImage from 'blueimp-load-image'

import 'react-image-crop/dist/ReactCrop.css'
import { trackPromise } from 'react-promise-tracker';

const checkWindow = (window, timer, reqBody, callback) => {
    try {
        var ur = window.location.href;
        if (ur.indexOf('oauth_verifier') != -1) {
            var verifier = "";
            var token = "";
            clearInterval(timer);
            window.close();

            ur = ur.substring(ur.indexOf('?') + 1);
            var urPartes = ur.split('&');

            for (let i = 0; i < urPartes.length; i++) {
                if (urPartes[i].indexOf('oauth_verifier') != -1) {
                    verifier = urPartes[i].split('=')[1];
                }
                if (urPartes[i].indexOf('oauth_token') != -1) {
                    token = urPartes[i].split('=')[1];
                }
            }

            localStorage.setItem('verifier', verifier);
            localStorage.setItem('token', token);
            reqBody['oauth_token'] = token;
            reqBody['oauth_verifier'] = verifier;
            let twitterTempAuth = localStorage.getItem('twitterTempAuth');
            twitterTempAuth = JSON.parse(twitterTempAuth);
            let requestToken = twitterTempAuth.oauth_token;
            if (requestToken !== token) {
                throw 'This action is unauthorized'
            }
            return callback(reqBody)
        }
    }
    catch (e) { console.log(e) }
}

function twitterImagePrep(img) {
    // Create an empty canvas element
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;

    // Copy the image contents to the canvas
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);

    // Get the data-URL formatted image
    // Firefox supports PNG and JPEG. You could check img.src to
    // guess the original format, but be aware the using "image/jpg"
    // will re-encode the image.
    let dataURL = canvas.toDataURL("image/png");
    let mimeType = dataURL.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)[1];
    dataURL = dataURL.replace(/^data:image\/(png|jpg|jpeg);base64,/, "");
    let length = atob(dataURL).length;

    return { dataUri: dataURL, length: length, media_type: mimeType }
}

class TwitterModal extends Component {
    state = {
        myRef: React.createRef()
    }

    componentWillUnmount = () => {
        clearInterval(this.timer)
    }

    getAccessTokenAndPost = (reqBody) => {
        return fetch(`${serverURL}twitter/access_token`,
            {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(reqBody)
            })
            .then(res => res.json())
            .then(res => {
                const { oauth_token, oauth_token_secret } = res
                reqBody['oauth_token'] = oauth_token;
                reqBody['oauth_token_secret'] = oauth_token_secret;
                return this.postStatus(reqBody)
            })
            .catch(err => this.setState({ error: JSON.stringify(err) }))
    }

    getToken = (reqBody) => {
        return fetch(`${serverURL}twitter/request_token`, { method: "POST" })
            .then(res => res.json())
            .then(res => {
                if (res.oauth_callback_confirmed) {
                    localStorage.setItem(`twitterTempAuth`, JSON.stringify(res))
                    return res.oauth_token
                }
            })
            .then(res => {
                let authWindow = window.open(`https://api.twitter.com/oauth/authenticate?oauth_token=${res}`, "",
                    "height=500,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no");
                this.timer = setInterval(() => checkWindow(authWindow, this.timer, reqBody, this.getAccessTokenAndPost), 1000);
            })
            .catch(err => this.setState({ error: JSON.stringify(err) }))
    }

    postStatus = (reqBody) => {
        fetch(`${serverURL}twitter/upload-media`,
            {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(reqBody)
            })
            .then(res => res.json())
            .then(res => JSON.parse(res.body))
            .then(res => res.media_id_string)
            .then(res => {
                reqBody['media_id'] = res;
                return fetch(`${serverURL}twitter/post-status`,
                    {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify(reqBody)
                    })
            })
            .then(res => res.json())
            .then(res => {
                let screen_name = res.user.screen_name;
                let id = res.id;
                let url = `https://twitter.com/${screen_name}/status/${id}`
                this.setState({ posted: true, url: url })
            })
            .then(res => {
                this.props.addPoints(10, "Twitter");
            })
            .catch(err => this.setState({ error: JSON.stringify(err) }))
    }

    tweet = () => {
        let twitterImg = this.state.myRef.current;
        let reqBody = twitterImagePrep(twitterImg);

        let status = get(this.props.post, "post/caption") + ' #' + get(this.props.post, "cashtag");
        reqBody['status'] = status;

        this.getToken(reqBody)
    };

    render() {
        return (
            <Modal show={this.props.show} onHide={() => {
                if (this.timer) {
                    clearInterval(this.timer)
                }
                this.props.hideModal()
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="text-center">
                            <i className="fab fa-twitter" />&nbsp;Share on Twitter
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-xs-12 text-center">
                            <div style={{
                                width: "96%", height: "96%", backgroundColor: "#e6f3f9",
                                margin: "2%", border: "1px solid #1BA1F1"
                            }}>
                                <p>{this.state.error}</p>
                                <div>
                                    {
                                        this.state.error
                                            ?
                                            <div>
                                                <p style={{ color: "red", marginTop: "20px", fontSize: "14px" }}>
                                                    {this.state.error.message}
                                                </p>
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                                <textarea style={{
                                    width: "728px",
                                    height: "108px",
                                    margin: "20px 20px 0px 20px",
                                    padding: "20px",
                                    textAlign: "center",
                                    fontSize: "15px"
                                }}
                                    placeholder={get(this.props.post, "post/caption") + ' #' + get(this.props.post, "cashtag")} />
                                <p className="text-left" style={{
                                    padding: "0px 20px",
                                    position: "relative",
                                    top: "-35px",
                                    left: "10px"
                                }}>
                                    <i className="fas fa-map-marker-alt" />&nbsp;{get(this.props.post, "post/location")}
                                </p>
                                <div style={{ textAlign: "left", padding: "0px 30px" }}>
                                    <img ref={this.state.myRef}
                                        crossOrigin="Anonymous"
                                        src={`http://s3.amazonaws.com/${s3Bucket}/${get(this.props.post, "post/imageURL")}`}
                                        style={{ width: "150px", height: "150px" }} />
                                </div>
                                <p className="text-right" style={{ padding: "0px 20px" }}>
                                    <Button disabled={this.state.error || this.state.posted} onClick={this.tweet}
                                        style={{
                                            height: "50px", width: "100px", backgroundColor: "#1ba1f1",
                                            borderRadius: "15px", color: "white", fontWeight: "800", fontSize: "16px"
                                        }}
                                    >
                                        <span>Tweet&nbsp;<i className="fas fa-feather-alt" /></span>
                                    </Button></p>
                                {
                                    this.state.posted
                                        ?
                                        <div>
                                            <p>Successfully posted!</p>

                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

class UserPost extends Component {
    state = {
        showTwitterModal: false
    }

    addPoints = (points, socialMedia) => {
        const walletId = get(this.props.user, ["user/wallet", "_id"]);
        const balance = get(this.props.user, ["user/wallet", "wallet/balance"]);
        const earnings = get(this.props.user, ["user/wallet", "wallet/earnings"]);
        const { postId } = this.props;

        let transaction = [{
            "_id": Number(walletId),
            "balance": `#(+ ${balance} ${points})`,
            "earnings": `#(+ ${earnings} ${points})`
        }];

        const tx2 = {
            "_id": Number(postId)
        };

        tx2[`sharedOn${socialMedia}`] = true;

        transaction.push(tx2);

        flureeFetch("/transact", transaction)
            .then(res => this.props.refreshUser())
            .then(res => {
                let newState = {}
                newState[`sharedOn${socialMedia}`] = true;
                this.setState(newState);
            })
            .catch(err => this.setState({ error: err }))
    }

    componentDidMount() {
        const { postId } = this.props;
        const postQuery = { select: ["*", { "cashtag": ["id"] }], from: Number(postId) }
        flureeFetch("/query", postQuery)
            .then(res => {
                let sharedOnFacebook = get(res, "post/sharedOnFacebook");
                let sharedOnTwitter = get(res, "post/sharedOnTwitter");
                let cashtag = get(res, ["cashtag", "id"]);
                cashtag = cashtag.substring(12);
                let post = res;
                post.cashtag = cashtag
                this.setState({ post: res, sharedOnFacebook: sharedOnFacebook, sharedOnTwitter: sharedOnTwitter })
            })
            .catch(err => this.setState({ error: err }))
    }

    openFBui = () => {
        let url = encodeURI(`http://s3.amazonaws.com/${s3Bucket}/${get(this.props.post, "post/imageURL")}`)
        window.FB.ui({
            method: 'share_open_graph',
            action_type: 'og.shares',
            display: 'popup',
            action_properties: JSON.stringify({
                object: {
                    'og:title': `${get(this.state.post, "post/caption")}`,
                    'og:description': `${get(this.state.post, "post/caption")}`,
                    'og:image': url
                }
            })
        }, (response) => {
            if (response && !response.error_message) {
                this.addPoints(10, "Facebook");
            } else {
                this.setState({ error: 'Error while posting.' });
            }
        });
    }

    render() {
        let { sharedOnFacebook, sharedOnTwitter } = this.state;
        return (
            <div>
                <TwitterModal show={this.state.showTwitterModal} post={this.state.post} addPoints={this.addPoints} hideModal={() => this.setState({ showTwitterModal: false })} />
                {
                    this.state.error
                        ?
                        <p style={{ color: "red" }}>{this.state.error}</p>
                        :
                        null
                }
                {
                    this.state.post
                        ?
                        <div>
                            <img src={`https://s3.amazonaws.com/${s3Bucket}/${get(this.state.post, "post/imageURL")}`}
                                style={{ width: "200px", height: "200px", borderRadius: "5px" }} />
                            <p style={{ fontSize: "1.1em" }}>{get(this.state.post, "post/caption")}</p>
                            <p style={{ fontSize: "0.8em" }}>{get(this.state.post, "post/location")}</p>

                            {this.props.user['user/handle'] === this.props.match.params.handle &&
                                <>
                                    <div style={{ paddingBottom: "5px" }}>
                                        <Button disabled={sharedOnFacebook}
                                            onClick={this.openFBui}> Share on Facebook </Button>
                                    </div>
                                    <div>
                                        <Button disabled={sharedOnTwitter}
                                            onClick={() => this.setState({ showTwitterModal: true })}> Share on Twitter</Button>
                                    </div>
                                </>
                            }
                        </div>
                        :
                        null
                }
            </div>
        )
    }
}

class NewPost extends Component {
    state = {
        crop: {
            unit: "px",
            width: 200,
            aspect: 1
        }
    }

    componentDidMount() {
        const uploadPageQuery = {
            users: { select: ["*"], from: "user", limit: 5 },
            cashtags: { select: ["ad/cashtag"], from: "ad/cashtag" }
        }
        flureeFetch("multi-query", uploadPageQuery)
            .then(res => {
                let { cashtags, users } = res;
                let cashtagOpts = [];

                cashtags.map(cashtag => {
                    let tag = get(cashtag, "ad/cashtag");
                    cashtagOpts.push({ value: tag, label: tag })
                });

                let handles = [];

                users.map(user => {
                    let handle = get(user, "user/handle");
                    handles.push({ value: handle, label: handle });
                })

                this.setState({
                    cashtags: cashtagOpts,
                    users: handles
                })
            })
            .catch(err => this.setState({ error: err }))
    }

    createPost() {
        const { data_uri, caption, cashtag, taggedUsers, location, cashtagFiletype } = this.state;
        const { _id } = this.props.user;

        const userArray = [];

        if (taggedUsers) {
            taggedUsers.map(user => userArray.push(["user/handle", user]))
        }

        let fileSuffix;
        if (cashtagFiletype === "image/jpeg") {
            fileSuffix = ".jpeg"
        } else if (cashtagFiletype === "image/png") {
            fileSuffix = ".png"
        } else {
            this.setState({ err: "" })
        }


        if ([fileSuffix, _id, caption, cashtag, location].every(el => el !== undefined)) {
            let fileName = caption.substr(0, 10) + Date.now() + fileSuffix
            const filePath = `user/${_id}/posts/${fileName}`;
            trackPromise(fetch(`${serverURL}s3/upload-file`, {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    buffer: data_uri,
                    name: filePath,
                    type: cashtagFiletype
                })
            })
                .then(res => {
                    let transaction = [{
                        _id: _id,
                        posts: ["post$1"]
                    },
                    {
                        _id: "post$1",
                        caption: caption,
                        location: location,
                        cashtag: ["_tag/id", `ad/cashtag:${cashtag.value}`],
                        users: userArray,
                        imageURL: filePath
                    }]

                    flureeFetch("/transact", transaction)
                        .then(res => {
                            const postId = get(res, ["tempids", "post$1"]);
                            this.props.history.push(`/user/${this.props.user['user/handle']}/post/${postId}`);
                        })
                        .catch(err => {
                            debugger;
                            this.setState({ error: err })
                        })
                }))
        }

    }

    // handleFile(e) {
    //     const reader = new FileReader();
    //     const file = e.target.files[0];

    //     if(file){
    //         reader.onload = (upload) => {
    //             let state = {}
    //             state["cashtagData_uri"] = upload.target.result;
    //             state["cashtagFiletype"] = file.type;
    //             state["cashtagFile"] = file;
    //         this.setState(state);
    //         };

    //         reader.readAsDataURL(file)
    //     }
    // }

    handleFile(e) {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]

            function loadImagePromise() {
                return new Promise((resolve, reject) => {
                    loadImage(file, (img) => { resolve(img) }, { orientation: true })
                })
            }

            loadImagePromise().then(canvas => {
                this.setState({ src: canvas.toDataURL(), cashtagFiletype: "image/jpeg" })
            })

        }

    }

    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                "newFile.jpeg"
            );
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error("Canvas is empty");
                    return;
                }
                blob.name = fileName;
                this.setState({ data_uri: canvas.toDataURL('image/jpeg') })
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
            }, "image/jpeg");
        });
    }

    handleSelectedUsers = (selectedOptions) => {
        const selectedUsers = []
        selectedOptions.map(option => selectedUsers.push(option.value))

        this.setState({ taggedUsers: selectedUsers })
    }

    render() {
        const { data_uri, src, crop, croppedImageUrl } = this.state;
        return (<>
            {src && (
                <div style={{ zIndex: 11, position: "absolute", top: 0, left: 0, bottom: 0, right: 0, backgroundColor: "rgba(217, 238, 250, 0.4)", borderRadius: "10px" }}>
                    <div style={{ maxWidth: "320px", margin: "20px auto", padding: "10px", backgroundColor: "rgb(183, 183, 183)", borderRadius: "5px" }}>
                        <ReactCrop style={{ margin: "0 auto" }} src={src} crop={crop} onImageLoaded={this.onImageLoaded} onComplete={this.onCropComplete} onChange={this.onCropChange} />
                        {/* <Row style={{ marginTop: "5px" }}>
                                <div className="d-flex justify-content-center"><Button className="d-block" style={{ margin: "0 auto"}}>Rotate Image!</Button></div>
                            </Row> */}
                        <Row style={{ marginTop: "10px" }}>
                            <div className="d-flex justify-content-center"><Button onClick={() => this.setState({ src: null })} className="d-block" style={{ margin: "0 auto" }}>Finished Cropping!</Button></div>
                        </Row>
                    </div>
                </div>
            )}
            <div>
                <div className="row" style={{ margin: "0px 20px" }}>
                    <div className="col-sm-4 text-left">
                        <h4 style={{ color: "#b73434" }}>Back</h4>
                    </div>
                    <div className="col-sm-4 text-center">
                        <h4>New Post</h4>
                    </div>
                </div>
                {
                    this.state.error
                        ?
                        <p style={{ color: "red" }}>{this.state.error}</p>
                        :
                        null
                }
                <div className="row" style={{ margin: "20px", paddingBottom: "20px" }}>
                    <div className="col-sm-3" style={{ height: "150px" }}>
                        <Form>
                            <div style={{ border: this.state.croppedImageUrl ? "none" : "2px dashed lightgrey", height: "150px", width: "150px" }}>
                                <Form.Group controlId="cashtagImage">
                                    {
                                        croppedImageUrl
                                            ?
                                            <img alt="Crop" style={{ maxHeight: "150px", display: "block", maxWidth: "150px", position: "absolute" }}
                                                src={croppedImageUrl} />
                                            :
                                            <div style={{ height: "150px", width: "150px", position: "absolute" }}>
                                                <p style={{ height: "150px", fontSize: "12px", padding: "50px 2px" }}>
                                                    Click or drag to upload a photo
                                        </p>
                                            </div>
                                    }
                                    <Form.Control type="file"
                                        onChange={(e) => this.handleFile(e)}></Form.Control>
                                </Form.Group>
                            </div>
                        </Form>
                    </div>
                    <div className="col-sm-9">
                        <Form style={{ height: "100px" }}>
                            <Form.Group controlId="caption">
                                <Form.Control as="textarea" rows="10" cols="30"
                                    style={{ height: "100px" }}
                                    placeholder="Write a Caption" value={this.state.caption}
                                    onChange={(e) => this.setState({ caption: e.target.value })} />
                            </Form.Group>
                        </Form>
                        <div style={{ height: "30px", width: "100%" }}>
                            <Select value={this.state.cashtag}
                                style={{ margin: "10px 0px" }}
                                onChange={(e) => this.setState({ cashtag: e })}
                                options={this.state.cashtags}
                            />
                        </div>
                    </div>
                    <hr />
                </div>
                <div className="row" style={{ margin: "0px 20px", paddingBottom: "20px" }}>
                    <div className="col-sm-12 text-left"
                        style={{ height: "60px", textAlign: "center" }}>
                        <Select
                            placeholder="Tag People"
                            isMulti
                            options={this.state.users}
                            onChange={this.handleSelectedUsers}
                        />
                    </div>
                </div>
                <div className="row" className="row" style={{ margin: "0px 20px", paddingBottom: "20px" }}>
                    <div className="col-sm-12 text-left">
                        <textarea placeholder="Add Location" style={{ height: "60px", width: "100%", padding: "10px" }}
                            onChange={(e) => this.setState({ location: e.target.value })}
                        />
                    </div>
                </div>
                <div className="row" style={{ margin: "0px 20px", paddingBottom: "20px" }}>
                    <div className="col-sm-12 text-right">
                        <div style={{ marginTop: "20px" }}>
                            <Button className="brand-btn" size="lg" onClick={() => this.createPost()}>Create Post</Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
        )
    }
}

class AllUserPosts extends Component {
    state = {
        posts: [],
        error: null
    }

    componentDidMount() {
        const { _id } = this.props.user;
        const allUserPostQuery = { select: [{ "user/posts": ["_id", "caption", "location", "imageURL", { "cashtag": ["id"] }] }], from: Number(_id) }
        flureeFetch("/query", allUserPostQuery)
            .then(res => {
                let userPosts = get(res, "user/posts");
                if (userPosts) {
                    for (let i = 0; i < userPosts.length; i++) {
                        let cashtag = get(userPosts[i], ["cashtag", "id"]);
                        cashtag = cashtag.substring(11);
                        userPosts[i]['cashtag'] = cashtag;
                    }
                    this.setState({ posts: userPosts })
                }
            })
            .catch(err => this.setState({ error: err }))
    }

    render() {
        return (
            <div className="row">
                {
                    this.state.error
                        ?
                        <p style={{ color: "red" }}>{this.state.error}</p>
                        :
                        null
                }
                {
                    this.state.posts && this.state.posts.length > 0
                        ?
                        this.state.posts.map(post => {
                            return (<div className="col-xs-4"
                                style={{ cursor: "pointer" }}
                                onClick={() => this.props.history.push(`/user/${this.props.user['user/handle']}/post/${post._id}`)}>
                                <img src={`https://s3.amazonaws.com/${s3Bucket}/${post.imageURL}`} style={{ height: "90%", width: "90%" }} />
                                <p>{post.caption}</p>
                                <p>{post.location}</p>
                                <p>{post.cashtag}</p>
                            </div>)
                        })
                        :
                        <div style={{ marginTop: "100px" }}>
                            <h4>This user has no posts yet. Upload a post today!</h4>
                        </div>
                }
            </div>
        )
    }
}

class AllPosts extends Component {
    state = {
        posts: [],
        error: null
    }

    componentDidMount() {
        const { _id } = this.props.user;
        const allPostQuery = {
            select: { "?posts": ["_id", "caption", "location", "imageURL", { "user/_posts": ["handle"] }, { "cashtag": ["id"] }] },
            where: [[null, "user/posts", "?posts"]]
        }
        // const allPostQuery = { select: ["user/handle", {"user/posts": ["_id", "caption", "location", "imageURL", {"cashtag": ["id"]}]}], from: "user/posts"}
        flureeFetch("/query", allPostQuery)
            .then(res => {
                // let userPosts = get(res, "user/posts");
                let userPosts = res;
                if (userPosts) {
                    for (let i = 0; i < userPosts.length; i++) {
                        let userHandle = get(userPosts[i], ["user/_posts", 0, "handle"])
                        userPosts[i]['handle'] = userHandle
                        let cashtag = get(userPosts[i], ["cashtag", "id"]);
                        cashtag = cashtag.substring(11);
                        userPosts[i]['cashtag'] = cashtag;
                        let caption = userPosts[i]['caption']
                        if (caption.length > 40) {
                            caption = caption.slice(0, 40) + "..."
                        }
                        userPosts[i]['caption'] = caption
                    }
                    this.setState({ posts: userPosts })
                }
            })
            .catch(err => this.setState({ error: err }))
    }

    render() {
        return (
            <div className="row">
                {
                    this.state.error
                        ?
                        <p style={{ color: "red" }}>{this.state.error}</p>
                        :
                        null
                }
                {
                    this.state.posts && this.state.posts.length > 0
                        ?
                        this.state.posts.map(post => {
                            return (<div className="col-xs-4"
                                style={{ cursor: "pointer" }}
                                onClick={() => this.props.history.push(`/user/${post.handle}/post/${post._id}`)}>
                                <p className="text-left post-handle" style={{ fontSize: "0.8em", width: "90%", margin: "0 auto" }}>{post.handle}</p>
                                <img src={`https://s3.amazonaws.com/${s3Bucket}/${post.imageURL}`} style={{ height: "90%", width: "90%", borderRadius: "5px" }} />
                                {/* <p>{post.caption}</p>
                            <p>{post.location}</p>
                            <p>{post.cashtag}</p> */}
                                <p style={{ fontSize: "0.9em" }}>{post.caption}</p>
                                <small>{post.cashtag}</small>
                            </div>)
                        })
                        :
                        <div style={{ marginTop: "100px" }}>
                            <h4>Nobody has uploaded any posts yet! Upload a post today!</h4>
                        </div>
                }
            </div>
        )
    }
}

class Post extends Component {
    state = {
        trendingInfluencers: []
    }

    componentDidMount() {
        const trendingInfluencersQuery = {
            "select": {
                "?user": [
                    "*"
                ]
            },
            "where": [
                [
                    "?user",
                    "user/wallet",
                    "?wallet"
                ],
                [
                    "?wallet",
                    "wallet/balance",
                    "?balance",
                    { "filter": "(> ?balance 50)" }
                ]
            ],
            "limit": 20
        }
        flureeFetch(`/query`, trendingInfluencersQuery)
            .then(res => this.setState({ trendingInfluencers: res }))
            .catch(err => this.setState({ error: err }))
    }

    render() {
        const { postId } = this.props.match.params
        return (
            <section className="profile-two" style={{ backgroundColor: "white" }}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-2">
                            <LeftSidebar {...this.props} />
                        </div>
                        <div className="col-lg-7 text-center" style={{ background: "#fff", marginTop: "20px", padding: "0px 50px" }}>
                            {
                                postId
                                    ?
                                    <UserPost {...this.props} postId={postId} />
                                    :
                                    <div>
                                        {
                                            this.props.all
                                                ?
                                                (this.props.match.path === '/explore' ? <AllPosts {...this.props} /> : <AllUserPosts {...this.props} />)
                                                :
                                                <NewPost {...this.props} />
                                        }
                                    </div>
                            }
                        </div>
                        <div className="col-lg-3">
                            {/* <RightSidebar trendingInfluencers = {this.state.trendingInfluencers}/> */}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Post;


// export default [{
//     "_id": "user$1",
//     "firstName": "Anthony",
//     "lastName": "McCartney",
//     "handle": "anthony",
//     "profilePic": "initialData/users/2.jpg",
//     "wallet": {
//         "_id": "wallet",
//         "name": "anthonyWallet",
//         "balance": 10,
//         "earnings": 10
//     },
//     "shoppingInterests": [["category/descriptiveName", "restaurants.fastFood"],
//     ["category/descriptiveName", "clothing.mens"]],
//     "registered": true
// },
// {
//     "_id": "user$2",
//     "firstName": "Anna",
//     "lastName": "Morgan",
//     "handle": "anna",
//     "profilePic": "initialData/users/1.jpg",
//     "wallet": {
//         "_id": "wallet",
//         "name": "annaWallet",
//         "balance": 10,
//         "earnings": 10
//     },
//     "shoppingInterests": [["category/descriptiveName", "household.kitchen"],
//     ["category/descriptiveName", "electronics.camera"]],
//     "registered": true
// },
// {
//     "_id": "user$3",
//     "firstName": "Sean",
//     "lastName": "Coleman",
//     "handle": "sean",
//     "profilePic": "initialData/users/4.jpg",
//     "wallet": {
//         "_id": "wallet",
//         "name": "seanWallet",
//         "balance": 10,
//         "earnings": 10
//     },
//     "shoppingInterests": [["category/descriptiveName", "travel.unitedStates"],
//     ["category/descriptiveName", "electronics.camera"]],
//     "registered": true
// },
// {
//     "_id": "user",
//     "firstName": "Grace",
//     "lastName": "Karen",
//     "handle": "grace",
//     "profilePic": "initialData/users/3.jpg",
//     "wallet": {
//         "_id": "wallet",
//         "name": "graceWallet",
//         "balance": 10,
//         "earnings": 10
//     },
//     "shoppingInterests": [["category/descriptiveName", "clothing"],
//     ["category/descriptiveName", "electronics"]],
//     "registered": true
// },
// {
//     "_id": "user",
//     "firstName": "Clifford",
//     "lastName": "Graham",
//     "handle": "clifford",
//     "profilePic": "initialData/users/6.jpg",
//     "wallet": {
//         "_id": "wallet",
//         "name": "cliffordWallet",
//         "balance": 10,
//         "earnings": 10
//     },
//     "shoppingInterests": [["category/descriptiveName", "clothing.womens"],
//     ["category/descriptiveName", "electronics.camera"]],
//     "registered": true
// },
// {
//     "_id": "user",
//     "firstName": "Vanessa",
//     "lastName": "Wells",
//     "handle": "vanessa",
//     "profilePic": "initialData/users/5.jpg",
//     "wallet": {
//         "_id": "wallet",
//         "name": "vanessaWallet",
//         "balance": 10,
//         "earnings": 10
//     },
//     "shoppingInterests": [["category/descriptiveName", "clothing.mens"],
//     ["category/descriptiveName", "clothing.mens.casual"], 
//     ["category/descriptiveName", "travel"]],
//     "registered": true
// },
// {
//     "_id": "user",
//     "firstName": "Derek",
//     "lastName": "Hanchi",
//     "handle": "dHanchi",
//     "gender": "male",
//     "email": "dhanchi@gmail.com",
//     "dateOfBirth": 667100938,
//     "profilePic": "initialData/users/19.jpg",
//     "wallet": {
//         "_id": "wallet",
//         "name": "vdHanchiWallet",
//         "balance": 10,
//         "earnings": 10
//     },
//     "shoppingInterests": [["category/descriptiveName", "clothing.mens"],
//     ["category/descriptiveName", "clothing.mens.casual"]],
//     "registered": true
// },
// {
//     "_id": "user",
//     "firstName": "Becky",
//     "lastName": "Hanson",
//     "handle": "bHanson",
//     "gender": "female",
//     "email": "bhanson@gmail.com",
//     "dateOfBirth": 885235954,
//     "profilePic": "initialData/users/20.jpg",
//     "wallet": {
//         "_id": "wallet",
//         "name": "bHansonWallet",
//         "balance": 10,
//         "earnings": 10
//     },
//     "shoppingInterests": [["category/descriptiveName", "clothing.womens"],
//     ["category/descriptiveName", "clothing.mens.casual"]],
//     "registered": true
// },
// {
//     "_id": "user",
//     "firstName": "Tina",
//     "lastName": "Harper",
//     "handle": "tHarper",
//     "email": "tharper@gmail.com",
//     "gender": "female",
//     "dateOfBirth": 540870538,
//     "profilePic": "initialData/users/21.jpg",
//     "wallet": {
//         "_id": "wallet",
//         "name": "tHarperWallet",
//         "balance": 10,
//         "earnings": 10
//     },
//     "shoppingInterests": [["category/descriptiveName", "clothing.mens"],
//     ["category/descriptiveName", "clothing.mens.casual"]],
//     "registered": true
// },
// {
//     "_id": "user",
//     "firstName": "Jacob",
//     "lastName": "Stephens",
//     "handle": "jStephens",
//     "profilePic": "initialData/users/22.jpg",
//     "gender": "male",
//     "dateOfBirth": 604028938,
//     "email": "jstevens@gmail.com",
//     "wallet": {
//         "_id": "wallet",
//         "name": "jStephensWallet",
//         "balance": 10,
//         "earnings": 10
//     },
//     "shoppingInterests": [["category/descriptiveName", "clothing.mens"],
//     ["category/descriptiveName", "clothing.mens.casual"]],
//     "registered": true
// }]

export default function(authId) {
    
    return [{
    "_id": "user",
    "firstName": "Admin",
    "lastName": "Account",
    "handle": "admin",
    "profilePic": "initialData/users/1.jpg",
    "wallet": {
        "_id": "wallet",
        "name": "adminWallet",
        "balance": 10,
        "earnings": 10
    },
    "shoppingInterests": [["category/descriptiveName", "clothing.mens"],
    ["category/descriptiveName", "clothing.mens.casual"], 
    ["category/descriptiveName", "travel"]],
    "registered": true,
    "adminAuth": "_auth$1"
},
{
    "_id": "_auth$1",
    "id": authId,
    "roles": [
      "_role/id", "Admin"
    ]
  },
  // {
  //     "_id": "_role$1",
  //     "id": "Admin",
  //     "doc": "Fabric Admin Role",
  //     "rules": [["_rule/id", "root"]]
  // }
]

}